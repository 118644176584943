define('sheer/controllers/preview-template', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({
    docJSON: computed('model.doc', function () {
      return JSON.stringify(this.get('model.doc'));
    }),
    isSVG: computed('model.template.assetType.name', function () {
      var name = this.get('model.template.assetType.name');
      switch (name) {
        case 'SVG Design Layout':
        case 'Simple Embellishment':
          return true;
        default:
          return false;
      }
    })
  });
});