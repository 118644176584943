define('sheer/components/template-property-editors/named-files/component', ['exports', 'ember', 'sheer/config/environment'], function (exports, _ember, _sheerConfigEnvironment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    host: _sheerConfigEnvironment['default'].host,
    updateLabel: function updateLabel(id) {
      this.set('fileElemId', id);
    },
    uploadingFiles: [],
    _pathToName: function _pathToName(file) {
      var path = file.name;
      return path.split('.')[0];
    },
    uploadStarted: function uploadStarted(files) {
      for (var i = 0; i < files.length; ++i) {
        this.uploadingFiles.push(this.get('propertyName') + '-' + this._pathToName(files[i]));
      }
      this.set('uploadIcon', 'spinner');
    },
    uploaded: function uploaded(res) {
      this.uploadingFiles = this.uploadingFiles.filter(function (x) {
        return x !== res['asset-template-blob'].blobName;
      });;
      if (this.uploadingFiles.length === 0) {
        this.set('uploadIcon', 'check');
      }
    },
    blobNameFromPropName: function blobNameFromPropName(propName, file) {
      return propName + '-' + this._pathToName(file);
    },
    removeBlob: function removeBlob(blobName) {
      var val = this.get('propertyValue');
      var toRemove = val.filter(function (b) {
        console.log(blobName, b);
        return get(b, 'blobName') === blobName;
      });
      val.removeObjects(toRemove);
    },
    _namedFileData: function _namedFileData(blob) {
      return {
        blobName: blob.name,
        blobId: blob.id,
        format: blob.extension,
        localName: blob.name.substring(this.get('propertyPath').length + 1)
      };
    }, onUpload: function onUpload(response) {
      var _this = this;

      this.uploaded(response);

      var blob = response['asset-template-blob'];
      var val = this.getWithDefault('propertyValue', []);
      if (!val.length) {
        val = [];
      }
      var found = false;

      val = val.map(function (x) {
        if (x.name === blob.name) {
          found = true;
          return _this._namedFileData(blob);
        } else {
          return x;
        }
      });

      if (!found) {
        val.push(this._namedFileData(blob));
      }

      this.get('dataChanged')({
        name: this.get('propertyPath'),
        type: 'namedFiles',
        data: val
      });
    },

    btnText: computed('propertyValue', function () {
      var as = this.get('alreadySet');

      if (as) {
        return 'Update Files';
      } else {
        return 'Upload Files';
      }
    }),

    alreadySet: computed('propertyValue', function () {
      var pv = this.get('propertyValue');

      return pv && pv.format && pv.format !== '';
    })
  });
});