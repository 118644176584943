define('sheer/helpers/compose', ['exports', 'ember-functional-helpers/helpers/compose'], function (exports, _emberFunctionalHelpersHelpersCompose) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberFunctionalHelpersHelpersCompose['default'];
    }
  });
  Object.defineProperty(exports, 'compose', {
    enumerable: true,
    get: function get() {
      return _emberFunctionalHelpersHelpersCompose.compose;
    }
  });
});