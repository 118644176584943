define('sheer/services/ref-types', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Service = _ember['default'].Service;
  var assert = _ember['default'].assert;
  var inject = _ember['default'].inject;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Service.extend({
    schmService: inject.service('schema-resolver'),

    _recurseProps: function _recurseProps(rd, subType, props) {
      var path = arguments.length <= 3 || arguments[3] === undefined ? [] : arguments[3];

      var res = [];
      if (props) {
        for (var subProp in props) {
          if (props.hasOwnProperty(subProp)) {
            path = path.concat([subProp]);
            var theProp = props[subProp];
            res.push({
              refDef: rd,
              srcType: subType,
              name: subProp,
              type: theProp.title || theProp.type,
              path: path.join('.'),
              fieldType: 'assetRef',
              prop: theProp
            });

            if (theProp.title !== 'file') {
              res = res.concat(this._recurseProps(rd, subType, theProp.properties, path));
            }
            path.pop();
          }
        }
      }

      return res;
    },

    loadRefsSchemas: function loadRefsSchemas(types, typ) {
      var _this = this;

      var resPromises = [];
      var refDefs = typ.get('assetRefDefs');
      var sSrv = this.get('schmService');
      refDefs.forEach(function (rd) {
        var subTypes = types.filter(function (t) {
          return t.get('id') === rd.get('refAssetType.id');
        });
        assert('Sub type should already exist in ember data', subTypes && subTypes.length > 0);

        var _subTypes = _slicedToArray(subTypes, 1);

        var subType = _subTypes[0];

        resPromises.push(new RSVP.Promise(function (resolve) {
          sSrv.onUpdate(subType, function () {
            var props = _this._recurseProps(rd, subType, sSrv.schema(subType).properties);
            resolve(props);
          });
        }));
        sSrv.loadSchemaForType(subType);
      });

      return RSVP.Promise.all(resPromises);
    }

  });
});