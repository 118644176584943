define('sheer/controllers/config/asset-type/asset-template', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'ember-concurrency'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin, _emberConcurrency) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    isExpanded: false,
    toggleExpanded: function toggleExpanded() {
      this.toggleProperty('isExpanded');
    },
    lookupTemplatesForType: function lookupTemplatesForType(type) {
      return this.get('store').query('asset-template', { 'filter': { 'assetTypeName': type } });
    },

    blobIds: computed('template.assetTemplateBlobs.[]', function () {
      return this.getWithDefault('template.assetTemplateBlobs', []).map(function (b) {
        return b.get('id');
      });
    }),

    blobNames: computed('template.assetTemplateBlobs.[]', function () {
      return this.getWithDefault('template.assetTemplateBlobs', []).map(function (b) {
        return { 'blobId': b.get('id'), 'name': b.get('name') };
      });
    }),

    createTemplate: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(data) {
      var tmpl, newone, thetype, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _loop, _iterator, _step, res, varDefs, model;

      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.set('templateSaved', false);
            this.set('previewSaved', false);
            this.set('saveStatus', null);

            tmpl = this.get('template');
            newone = null;

            if (tmpl && data.version === tmpl.get('version')) {
              newone = tmpl;
              newone.documentTemplate = data.documentTemplate;
            } else {
              newone = this.get('store').createRecord('asset-template', {
                name: data.name || this.get('name'),
                version: data.version || this.get('version') || 0,
                documentTemplate: data.documentTemplate || this.get('document_template')

              });
            }

            thetype = this.get('store').peekRecord('asset-type', data.assetTypeId);

            newone.set('assetType', thetype);

            _iteratorNormalCompletion = true;
            _didIteratorError = false;
            _iteratorError = undefined;
            context$1$0.prev = 11;

            _loop = function () {
              var blobid = _step.value;

              var theblob = _this.get('store').peekRecord('asset-template-blob', blobid);
              if (!theblob) {
                theblob = _this.get('store').createRecord('asset-template-blob', {
                  id: blobid
                });
              }

              if (newone.get('assetTemplateBlobs').filter(function (x) {
                return x.id === blobid;
              }).length == 0) {
                newone.get('assetTemplateBlobs').pushObject(theblob);
              }
            };

            for (_iterator = data.blobs[Symbol.iterator](); !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              _loop();
            }

            context$1$0.next = 20;
            break;

          case 16:
            context$1$0.prev = 16;
            context$1$0.t0 = context$1$0['catch'](11);
            _didIteratorError = true;
            _iteratorError = context$1$0.t0;

          case 20:
            context$1$0.prev = 20;
            context$1$0.prev = 21;

            if (!_iteratorNormalCompletion && _iterator['return']) {
              _iterator['return']();
            }

          case 23:
            context$1$0.prev = 23;

            if (!_didIteratorError) {
              context$1$0.next = 26;
              break;
            }

            throw _iteratorError;

          case 26:
            return context$1$0.finish(23);

          case 27:
            return context$1$0.finish(20);

          case 28:
            data.variableDefs.forEach(function (v) {
              var thevar = _this.get('store').peekRecord('variable-def', v.id);
              if (!thevar) {
                thevar = _this.get('store').createRecord('variable-def', {
                  name: v.name,
                  type: v.type,
                  applicator: v.applicator,
                  applicatorData: v.applicatorData || '',
                  hint: v.hint || '',
                  'default': v['default'] || ''
                });
              }
              newone.get('variableDefs').pushObject(thevar);
            });

            context$1$0.next = 31;
            return newone.save();

          case 31:
            res = context$1$0.sent;
            varDefs = this.get('store').peekAll('variable-def');

            varDefs.filter(function (x) {
              return x.currentState.isNew;
            }).forEach(function (y) {
              return _this.get('store').unloadRecord(y);
            });

            model = this.get('model');

            this.transitionToRoute('config.asset-type.asset-template', model.type.get('id'), res.get('id'));

          case 36:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this, [[11, 16, 20, 28], [21,, 23, 27]]);
    })).restartable(),

    typeSelected: function typeSelected(data) {
      this.set('template.assetType', data);
    },

    actions: {
      'delete': function _delete(id) {
        var _this2 = this;

        var delone = this.get('store').peekRecord('asset_template', id);
        this.get('store').deleteRecord(delone);

        delone.save().then(function () {
          _this2.transitionToRoute('config.asset-type.asset-templates', _this2.get('model.type.id'));
        });
      },

      create: function create(data) {
        this.get('createTemplate').perform(data);
      }

    }
  });
});

// So the save relationships plugin is annoying and doesn't actually work correctly with the actual jsonapi
//   spec so here we do some cleanup