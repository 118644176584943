define('sheer/controllers/asset-library/types/index', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({
    breadCrumb: 'Asset Types',
    breadCrumbPath: 'asset-library.types.index',

    typeNames: computed('types.[]', function () {
      var typs = this.get('types');
      var names = [];

      var res = {};
      typs.forEach(function (x) {
        if (!res[x.get('name')]) {
          res[x.get('name')] = [x];
          names.push(x.get('name'));
        } else {
          res[x.get('name')].pushObject(x);
        }
      });

      names.forEach(function (n) {
        res[n] = res[n].sort(function (x, y) {
          return x.get('version') < y.get('version');
        });
      });

      return res;
    })
  });
});