define('sheer/routes/asset-designer', ['exports', 'ember'], function (exports, _ember) {
  var RSVP = _ember['default'].RSVP;
  var service = _ember['default'].inject.service;
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    session: service('session'),

    renderTemplate: function renderTemplate() {
      this.render();

      this.render('designer-menu', {
        into: 'application',
        outlet: 'assetMenu'
      });
    },
    setupController: function setupController(controller, models) {
      this._super(controller, models);

      controller.set('types', models.types);
      controller.set('assets', models.assets);
      controller.set('projects', models.projects);
      controller.set('templates', models.templates);
      controller.set('fields', models.fields);
    },
    model: function model() {
      return RSVP.hash({
        types: this.get('store').findAll('asset-type'),
        templates: this.get('store').findAll('asset-template'),
        assets: this.get('store').findAll('asset'),
        projects: this.get('session.isAuthenticated') ? this.get('store').findAll('project') : [],
        fields: this.get('session.isAuthenticated') ? this.get('store').findAll('field') : []
      });
    }
  });
});