define('sheer/components/version-nested-menu/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var EStr = _ember['default'].String;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: '',
    showStyle: EStr.htmlSafe('display:block'),

    _itemComparator: function _itemComparator(a, b) {
      var aname = a.get('name') || 'ZZZZZZ';
      var bname = b.get('name') || 'ZZZZZZ';
      var aver = a.get('version');
      if (aver === undefined) {
        aver = 9999;
      }
      var bver = b.get('version');
      if (bver === undefined) {
        bver = 9999;
      }

      if (aname === bname) {
        return bver - aver;
      } else {
        return aname > bname ? -1 : 1;
      }
    },

    menuMap: computed('items.content.length', function () {

      var contextField = this.get('menuContextField');
      var contextVal = this.get('menuContext');

      var items = this.get('items');
      var map = {};

      if (items) {
        map = items.reduce(function (s, x) {

          if (contextField && x && x.get(contextField).get('name') !== contextVal.get('name')) {
            return s;
          }

          var name = x.get('name');
          x.set('typeVersion', contextField && x ? x.get(contextField).get('version') : "unk");

          var curVal = s[name] || [];
          curVal.push(x);
          s[name] = curVal;
          return s;
        }, {});
      }

      var sortedMap = {};

      for (var key in map) {
        sortedMap[key] = map[key].sort(this._itemComparator);
      }

      return sortedMap;
    })
  });
});