define('sheer/adapters/project', ['exports', 'sheer/adapters/application', 'ember', 'sheer/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _sheerAdaptersApplication, _ember, _sheerConfigEnvironment, _emberSimpleAuthMixinsDataAdapterMixin) {
  var _Ember$String = _ember['default'].String;
  var pluralize = _Ember$String.pluralize;
  var underscore = _Ember$String.underscore;
  exports['default'] = _sheerAdaptersApplication['default'].extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
    host: _sheerConfigEnvironment['default'].aftHost,
    namespace: 'survey',
    authorizer: 'authorizer:oauth2',
    pathForType: function pathForType(modelName) {
      // I would rather have rails dasherize the routes but I couldn't think of
      // a good way to dasherize everything without using private apis ¯\_(ツ)_/¯
      return pluralize(underscore(modelName));
    }
  });
});