define('sheer/components/template-property-editors/simple', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    updatePropertyValue: function updatePropertyValue(newVal) {
      this.get('dataChanged')({
        name: this.get('propertyPath'),
        type: 'font',
        data: newVal
      });
    }
  });
});