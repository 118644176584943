define('sheer/components/preview-dependency-editor/component', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    newDep: {},

    _update: function _update(deps) {
      this.update(deps);
    },
    addDep: function addDep() {
      var oldDeps = this.get('deps');
      var newDeps = [].concat(_toConsumableArray(oldDeps), [this.get('newDep')]);
      this._update(newDeps);
      this.set('newDep', {});
    },
    updateDepName: function updateDepName(dep, val) {
      set(dep, 'name', val);
      this._update(this.get('deps'));
    },
    updateDepRefId: function updateDepRefId(dep, val) {
      set(dep, 'asset-id', val);
      this._update(this.get('deps'));
    }

  });
});