define('sheer/components/template-property-editors/pretty-file-uploader/component', ['exports', 'ember', 'sheer/config/environment'], function (exports, _ember, _sheerConfigEnvironment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    host: _sheerConfigEnvironment['default'].host,
    updateLabel: function updateLabel(id) {
      this.set('fileElemId', id);
    },
    uploadStarted: function uploadStarted() {
      this.set('uploadIcon', 'spinner');
      console.log('started');
    },
    uploaded: function uploaded() {
      this.set('uploadIcon', 'check');
      this.set('uploadStatus', '');
      console.log('done');
    },
    onUpload: function onUpload(response) {
      this.uploaded();
      this.get('dataChanged')({
        name: this.get('propertyPath'),
        type: 'fileUpload',
        data: {
          blobName: response['asset-template-blob'].name,
          format: response['asset-template-blob'].extension
        },
        blobId: response['asset-template-blob'].id
      });
    },
    onError: function onError(response) {
      console.log('upload error');
      this.set('uploadIcon', null);
      this.set('uploadStatus', response.responseText);
    },
    btnText: computed('propertyValue', function () {
      var as = this.get('alreadySet');

      if (as) {
        return 'Update File';
      } else {
        return 'Upload File';
      }
    }),
    alreadySet: computed('propertyValue', function () {
      var pv = this.get('propertyValue');

      return pv && pv.format && pv.format !== '';
    })
  });
});