define('sheer/components/template-property-editors/font-styler', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var eStr = _ember['default'].String;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    boldAttr: computed('isBold', function () {
      return eStr.htmlSafe(this.get('isBold') ? 'active' : '');
    }),

    italAttr: computed('isItalic', function () {
      return eStr.htmlSafe(this.get('isItalic') ? 'active' : '');
    }),

    isBold: computed('propertyValue', function () {
      var propValue = this.get('propertyValue');
      if (propValue) {
        return propValue.isBold;
      }
      return null;
    }),

    isItalic: computed('propertyValue', function () {
      var propValue = this.get('propertyValue');
      if (propValue) {
        return propValue.isItalic;
      }
      return null;
    }),

    fontSize: computed('propertyValue', function () {
      var propValue = this.get('propertyValue');
      if (propValue) {
        return parseInt(propValue.size, 10);
      }
      return null;
    }),

    sizeChanged: function sizeChanged(newSize) {
      this.set('fontSize', parseInt(newSize, 10));
      this.handleChange();
    },

    boldClicked: function boldClicked() {
      this.toggleProperty('isBold');
      this.handleChange();
    },
    italicClicked: function italicClicked() {
      this.toggleProperty('isItalic');
      this.handleChange();
    },

    handleChange: function handleChange() {
      this.get('dataChanged')({
        name: this.get('propertyPath'),
        type: 'font',
        data: {
          'isBold': this.get('isBold'),
          'isItalic': this.get('isItalic'),
          'size': this.get('fontSize')
        }
      });
    }
  });
});