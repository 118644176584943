define('sheer/routes/preview-template', ['exports', 'ember', 'sheer/config/environment'], function (exports, _ember, _sheerConfigEnvironment) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  var $ = _ember['default'].$;
  exports['default'] = Route.extend({
    model: function model(params) {
      return RSVP.hash({
        template: this.get('store').findRecord('asset-template', params.templateId),
        doc: $.getJSON(_sheerConfigEnvironment['default'].host + '/preview/template/' + params.templateId)
      });
    }
  });
});