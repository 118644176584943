define('sheer/components/template-property-editors/family-or-generic/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    familyName: computed('propertyValue', function () {
      var v = this.get('propertyValue');
      return !v || v.isGeneric ? '' : v.family;
    }),
    update: function update(val) {

      this.set('familyName', val);
      this.get('dataChanged')({
        name: this.get('propertyPath'),
        type: 'familyOrGeneric',
        data: {
          isGeneric: !val || val.trim().length === 0,
          family: val
        }
      });
    }
  });
});