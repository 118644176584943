define('sheer/adapters/tag', ['exports', 'sheer/adapters/application', 'sheer/config/environment', 'ember'], function (exports, _sheerAdaptersApplication, _sheerConfigEnvironment, _ember) {
  var _Ember$String = _ember['default'].String;
  var pluralize = _Ember$String.pluralize;
  var underscore = _Ember$String.underscore;
  exports['default'] = _sheerAdaptersApplication['default'].extend({
    host: _sheerConfigEnvironment['default'].host,
    pathForType: function pathForType(modelName) {
      // I would rather have rails dasherize the routes but I couldn't think of
      // a good way to dasherize everything without using private apis ¯\_(ツ)_/¯
      return pluralize(underscore(modelName));
    }
  });
});