define("sheer/templates/components/schema-field-checkbox", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 15,
              "column": 0
            }
          },
          "moduleName": "sheer/templates/components/schema-field-checkbox.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["checkbox ", ["subexpr", "if", [["get", "property.readonly", ["loc", [null, [9, 28], [9, 45]]], 0, 0, 0, 0], "disabled", null], [], ["loc", [null, [9, 23], [9, 63]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "selected", ["loc", [null, [11, 40], [11, 48]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [11, 8], [11, 50]]], 0, 0], ["content", "option", ["loc", [null, [12, 14], [12, 24]]], 0, 0, 0, 0]],
        locals: ["option", "selected"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 27
          }
        },
        "moduleName": "sheer/templates/components/schema-field-checkbox.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "multiselect-checkboxes", [], ["tagName", "div", "options", ["subexpr", "@mut", [["get", "property.validValues", ["loc", [null, [3, 10], [3, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "onchange", ["subexpr", "action", ["update"], [], ["loc", [null, [4, 11], [4, 28]]], 0, 0], "selection", ["subexpr", "@mut", [["get", "value", ["loc", [null, [5, 12], [5, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "disabled", ["subexpr", "@mut", [["get", "property.readonly", ["loc", [null, [6, 11], [6, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "name", ["subexpr", "@mut", [["get", "key", ["loc", [null, [7, 7], [7, 10]]], 0, 0, 0, 0]], [], [], 0, 0], "updateSelectionValue", false], 0, null, ["loc", [null, [1, 0], [15, 27]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});