define('sheer/helpers/classy', ['exports', 'ember-cli-stylist/helpers/classy'], function (exports, _emberCliStylistHelpersClassy) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliStylistHelpersClassy['default'];
    }
  });
  Object.defineProperty(exports, 'classy', {
    enumerable: true,
    get: function get() {
      return _emberCliStylistHelpersClassy.classy;
    }
  });
});