define('sheer/helpers/lookup-property-value', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.lookupPropertyValue = lookupPropertyValue;
  var assert = _ember['default'].assert;
  var Helper = _ember['default'].Helper;

  function lookupPropertyValue(params /*, hash*/) {
    assert(params.length === 3, 'lookup-property-value must take 3 arguments');

    var _params = _slicedToArray(params, 3);

    var doc = _params[0];
    var obj = _params[1];
    var field = _params[2];

    doc = JSON.parse(doc || '{}');

    return doc && (field ? doc[obj] && doc[obj][field] : doc[obj]);
  }

  exports['default'] = Helper.helper(lookupPropertyValue);
});