define('sheer/components/light-table/cells/one-way-input-cell', ['exports', 'ember', 'ember-one-way-controls'], function (exports, _ember, _emberOneWayControls) {
  var set = _ember['default'].set;
  exports['default'] = _emberOneWayControls['default'].OneWayInput.extend({
    NON_ATTRIBUTE_BOUND_PROPS: ['table', 'column', 'row', 'rawvalue'],
    classNames: 'table-cell-spaced',

    update: 'doUpdate',

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('update', function (x) {
        var data = _this.get('row').content;
        var col = _this.get('column').valuePath;
        set(data, col, x);
      });
    }
  });
});