define('sheer/models/asset_type', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend({

    name: attr('string'),
    version: attr('number'),
    metadata: attr(),
    documentSchema: attr('string'),

    assetRefDefs: hasMany('assetRefDefs', { inverse: 'assetType' }),
    assetTemplates: hasMany('assetTemplates', { inverse: 'assetType' })

  });
});