define('sheer/components/template-property-editors/map/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    didUpdatePlace: function didUpdatePlace(a) {
      this.get('dataChanged')({
        name: this.get('propertyPath'),
        type: 'location',
        data: {
          'latitude': a.lat,
          'longitude': a.lng,
          'used': true
        }
      });
    },
    invalidUserSelection: function invalidUserSelection() {},
    updateUsed: function updateUsed(val) {
      console.log('used', val);
      this.get('dataChanged')({
        name: this.get('propertyPath'),
        type: 'location',
        data: {
          'latitude': this.get('propertyValue.latitude'),
          'longitude': this.get('propertyValue.longitude'),
          'used': val
        }
      });
    },
    propVal: computed('propertyValue.latitude', 'propertyValue.longitude', function () {
      return JSON.stringify(this.get('propertyValue'));
    }),
    myLat: computed('propertyValue.latitude', function () {
      return this.get('propertyValue.latitude');
    }),
    myLng: computed('propertyValue.longitude', function () {
      return this.get('propertyValue.longitude');
    }),
    zoom: 13

  });
});