define('sheer/components/template-property-editors/color-picker-text', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;

  var RGB_COLOR = /rgb\(([0-9]+),\s*([0-9]+),\s*([0-9]+)\)/;

  exports['default'] = Component.extend({
    // Function to convert hex format to a rgb color
    hexcol: function hexcol(c) {
      return ('0' + parseInt(c, 10).toString(16)).slice(-2);
    },

    rgb2hex: function rgb2hex(rgb) {
      rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
      return rgb && rgb.length === 4 ? '#' + this.hexcol(rgb[1]) + this.hexcol(rgb[2]) + this.hexcol(rgb[3]) : '';
    },

    colorChanged: function colorChanged(color) {
      var matches = color.match(RGB_COLOR);
      this.get('dataChanged')({
        name: this.get('propertyPath'),
        type: 'color',
        data: {
          r: parseInt(matches[1], 10),
          g: parseInt(matches[2], 10),
          b: parseInt(matches[3], 10),
          hex: this.rgb2hex(color)
        }
      });
    },

    init: function init() {
      this._super.apply(this, arguments);

      var curColor = this.get('propertyValue');
      if (curColor) {
        this.set('color', 'rgb(' + curColor.r + ',' + curColor.g + ',' + curColor.b + ')');
      }
    }
  });
});