define('sheer/models/asset-ref', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({

    asset: belongsTo('asset', { inverse: 'assetRefs' }),
    name: attr('string'),
    refAsset: attr(),
    refAssetTemplate: attr()

  });
});