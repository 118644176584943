define("sheer/templates/designer-menu", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 2
              },
              "end": {
                "line": 23,
                "column": 2
              }
            },
            "moduleName": "sheer/templates/designer-menu.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "one-way-select", [], ["id", "selAssetTypeInput", "class", "form-control", "options", ["subexpr", "@mut", [["get", "labeledTypes", ["loc", [null, [16, 29], [16, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "id", "optionLabelPath", "label", "includeBlank", true, "value", ["subexpr", "@mut", [["get", "selType", ["loc", [null, [20, 27], [20, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["get", "typeSelected", ["loc", [null, [21, 36], [21, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 28], [21, 49]]], 0, 0]], ["loc", [null, [14, 4], [22, 6]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 2
              },
              "end": {
                "line": 35,
                "column": 2
              }
            },
            "moduleName": "sheer/templates/designer-menu.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "one-way-select", [], ["id", "selAssetTemplateInput", "class", "form-control", "options", ["subexpr", "@mut", [["get", "templates", ["loc", [null, [28, 29], [28, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "id", "optionLabelPath", "name", "includeBlank", true, "value", ["subexpr", "@mut", [["get", "selTemplate", ["loc", [null, [32, 27], [32, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["get", "templateSelected", ["loc", [null, [33, 36], [33, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 28], [33, 53]]], 0, 0]], ["loc", [null, [26, 4], [34, 6]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 36,
              "column": 0
            }
          },
          "moduleName": "sheer/templates/designer-menu.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          dom.setAttribute(el1, "for", "selProjectInput");
          var el2 = dom.createTextNode("Project:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "one-way-select", [], ["id", "selProjectInput", "class", "form-control", "options", ["subexpr", "@mut", [["get", "projects", ["loc", [null, [5, 27], [5, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "id", "optionLabelPath", "title", "includeBlank", true, "value", ["subexpr", "@mut", [["get", "selProject", ["loc", [null, [9, 25], [9, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["get", "projectSelected", ["loc", [null, [10, 34], [10, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 26], [10, 50]]], 0, 0]], ["loc", [null, [3, 2], [11, 18]]], 0, 0], ["block", "if", [["get", "selProject", ["loc", [null, [13, 8], [13, 18]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [13, 2], [23, 9]]]], ["block", "if", [["get", "selType", ["loc", [null, [25, 8], [25, 15]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [25, 2], [35, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 36,
              "column": 0
            },
            "end": {
              "line": 38,
              "column": 0
            }
          },
          "moduleName": "sheer/templates/designer-menu.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "mini-login-form", [], ["login", "login", "errorMessage", ["subexpr", "@mut", [["get", "loginErrorMessage", ["loc", [null, [37, 47], [37, 64]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [37, 2], [37, 66]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 39,
            "column": 0
          }
        },
        "moduleName": "sheer/templates/designer-menu.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "session.isAuthenticated", ["loc", [null, [1, 6], [1, 29]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [38, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});