define('sheer/routes/config/asset-type/asset-templates', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  var service = _ember['default'].inject.service;
  exports['default'] = Route.extend({
    session: service('session'),
    controllerName: 'config.asset-type.asset-template',
    renderTemplate: function renderTemplate(controller) {

      this.render();

      controller.set('isTmplActive', true);

      this.render('asset-menu', {
        into: 'application',
        outlet: 'assetMenu'
      });
    },
    setupController: function setupController(controller, models) {
      var _this = this;

      this._super(controller, models);

      controller.set('templates', models.templates);
      controller.set('types', models.types);
      controller.set('type', models.type);
      controller.set('applicators', models.applicators);
      controller.set('fields', this.get('store').peekAll('field'));
      controller.set('tags', models.tags);
      controller.set('variables', []);

      var depTypes = models.type.get('assetRefDefs');

      depTypes.forEach(function (t) {
        var refId = t.get('refAssetType.id');
        var inStore = _this.get('store').peekRecord('asset-type', refId);
        if (!inStore || inStore.get('documentSchema') == null) {
          _this.get('store').findRecord('asset-type', refId, { reload: true });
        }
      });
    },
    model: function model(params) {
      return RSVP.hash({
        templates: this.get('store').findAll('asset-template'),
        types: this.get('store').findAll('asset-type'),
        type: this.get('store').findRecord('asset-type', params.assettypeId, { reload: true }),
        fields: this.get('store').findAll('field'),
        project: this.get('store').findAll('project'),
        tags: this.get('store').findAll('tag')
      });
    }
  });
});