define('sheer/components/light-table/cells/value-type-cell', ['exports', 'ember', 'ember-one-way-controls/components/one-way-select'], function (exports, _ember, _emberOneWayControlsComponentsOneWaySelect) {
  var computed = _ember['default'].computed;
  exports['default'] = _emberOneWayControlsComponentsOneWaySelect['default'].extend({
    NON_ATTRIBUTE_BOUND_PROPS: ['table', 'column', 'row', 'rawvalue'],
    classNames: 'table-cell-spaced',

    valueTypeEnum: computed(function () {
      return [{ label: 'Project Data', id: 'projectData' }, { label: 'Asset Ref', id: 'assetRef' }, { label: 'Asset Obj Ref', id: 'assetObjRef' }, { label: 'Override', id: 'static' }];
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('options', this.get('valueTypeEnum'));
      this.set('optionLabelPath', 'label');
      this.set('optionValuePath', 'id');

      this.set('value', { 'id': this.get('row.valueType') || this.get('valueType') });

      this.set('update', function (x) {
        var data = _this.get('row') || _this.get('variable');

        var varTypeChanged = _this.get('table.varTypeChanged') || _this.get('varTypeChanged');

        if (varTypeChanged) {
          varTypeChanged(data, x);
        }
      });
    }
  });
});