define('sheer/routes/asset-library/types/templates', ['exports', 'ember', 'sheer/config/environment'], function (exports, _ember, _sheerConfigEnvironment) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Route.extend({
    _makeStubs: function _makeStubs(assets) {
      return assets.map(function (x) {
        return {
          'id': parseInt(x.get('id')),
          'version': x.get('version'),
          'asset-type': { 'id': x.get('assetType.id') }
        };
      });
    },
    _includeRenders: function _includeRenders(assets, renders, key) {
      assets.forEach(function (x) {
        x.set(key, renders[parseInt(x.get('id'))]);
      });
      return assets;
    },
    _getRenders: function _getRenders(assets, fmt, height, key) {
      var _this = this;

      return new Promise(function (res, rej) {
        return $.ajax({
          'type': 'post',
          'url': _sheerConfigEnvironment['default'].renderHost + '/asset-renders/searches?format=' + fmt + '&height=' + height + '&quality=lowest',
          'statusCode': { 201: function _(x) {
              return res(_this.get('_includeRenders')(assets, x, key));
            } },
          'data': JSON.stringify({ 'asset-stubs': _this.get('_makeStubs')(assets) }),
          'contentType': 'application/json',
          'processData': false,
          'error': function error(resp, status, _error) {
            rej(_error);
          }
        });
      });
    },
    _buildPreviewAssets: function _buildPreviewAssets(controller, assets) {
      controller.set('previewAssets', assets.reduce(function (m, v) {
        var tmplId = v.get('previewsTemplate');
        if (tmplId) {
          m.set(tmplId, v);
        }
        return m;
      }, new Map()));
    },
    setupController: function setupController(controller, models) {
      var _this2 = this;

      this._super(controller, models);
      controller.set('templates', models.templates);
      controller.set('typeName', models.typeName);
      controller.set('type', models.type);
      controller.set('types', models.types);
      controller.set('tags', models.tags);

      this.get('store').query('asset', {
        filter: {
          previewsTemplate: '[' + models.templates.map(function (x) {
            return x.get('id');
          }).join(',') + ']'
        }
      }).then(function (x) {
        return _this2._getRenders(x, "jpg", 160, 'thumbnailUrl');
      }).then(function (x) {
        return _this2._getRenders(x, "jpg", 900, 'previewUrl');
      }).then(function (x) {
        return _this2._buildPreviewAssets(controller, x);
      });
    },
    model: function model(params) {
      return RSVP.hash({
        types: this.get('store').findAll('asset-type'),
        typeName: params.typeId,
        tags: this.get('store').findAll('tag'),
        templates: this.get('store').query('asset-template', {
          filter: {
            assetTypeName: params.typeId
          }
        })
      });
    }
  });
});