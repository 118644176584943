define('sheer/router', ['exports', 'ember', 'sheer/config/environment'], function (exports, _ember, _sheerConfigEnvironment) {
  var Router = _ember['default'].Router;

  var AppRouter = Router.extend({
    location: _sheerConfigEnvironment['default'].locationType,
    rootURL: _sheerConfigEnvironment['default'].rootURL
  });

  AppRouter.map(function () {
    this.route('config', function () {
      this.route('asset-types');
      this.route('asset-type', { path: '/asset-type' }, function () {
        this.route('index', { path: '/:assettypeId' });
        this.route('asset-templates', { path: '/:assettypeId/asset-templates' });
        this.route('asset-template', { path: '/:assettypeId/asset-template/:assetTemplateId' });
      });
    });
    this.route('preview-asset', { path: '/preview-asset/:assetId' });
    this.route('preview-template', { path: '/preview-template/:templateId' });
    this.route('asset-designer');

    this.route('asset-library', { path: '/asset-library' }, function () {
      this.route('types', function () {
        this.route('index', { path: '/' });
        this.route('templates', { path: '/:typeId/templates/' });
      });
    });
  });

  exports['default'] = AppRouter;
});