define('sheer/components/variable-assignment-form/component', ['exports', 'ember', 'ember-light-table'], function (exports, _ember, _emberLightTable) {
  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    canLoadMore: true,
    isLoading: false,
    extractions: [],

    init: function init() {
      this._super.apply(this, arguments);

      this.sendAction('variablesUpdated', this.get('vars'));
    },

    columns: computed(function () {
      return [{ label: 'Name', valuePath: 'name', width: '150px' }, { label: 'Hint', valuePath: 'hint', width: '200px' }, { label: 'Value Type', valuePath: 'valueType', width: '200px', cellComponent: 'light-table/cells/value-type-cell' }, { label: 'Value', valuePath: 'valueData', width: '200px', cellComponent: 'light-table/cells/value-data-cell' }, { label: 'Current Value', valuePath: 'valueRes', width: '200px' }];
    }),

    table: computed('vars', 'template', 'project.id', 'fields', 'answers.[]', function () {
      var _this = this;

      var table = new _emberLightTable['default'](this.get('columns'), this.get('vars'));
      table.set('project', this.get('project'));
      table.set('textFields', this.get('textFields'));
      table.set('refFields', this.get('refFields'));
      table.set('refDefs', this.get('refDefs'));
      table.set('answers', this.get('answers'));
      table.set('varTypeChanged', function (data, arg) {
        return _this._variableTypeUpdated(data, arg);
      });
      table.set('varValChanged', function (data, arg) {
        return _this._variableValueUpdated(data, arg);
      });

      return table;
    }),

    vars: computed('project.id', 'template.id', 'answers.[]', 'refFields.[]', function () {
      var _this2 = this;

      var vars = this.get('template.variableDefs');
      var template = JSON.parse(this.get('template.documentTemplate'));
      var mapping = template.DataMapping;
      var mappingMap = {};
      if (mapping) {
        mapping.forEach(function (x) {
          mappingMap[x.variableName] = x;
        });

        vars.forEach(function (v) {
          var name = v.get('name');
          var map = mappingMap[name];
          if (map) {
            v.set('valueType', map.dataRefSource);

            if (map.dataRefSource === 'projectData') {
              v.set('selField', _this2._getField(map.dataRefId));
            } else if (map.dataRefSource === 'assetRef') {
              (function () {
                // Need to get path & refDef

                var _map$dataRefId$split = map.dataRefId.split('.');

                var _map$dataRefId$split2 = _toArray(_map$dataRefId$split);

                var ref = _map$dataRefId$split2[0];

                var path = _map$dataRefId$split2.slice(1);

                var refFields = _this2.get('refFields');

                var fndField = !refFields ? [] : refFields.filter(function (x) {
                  return x.refDef.get('name') === ref && x.path === path.join('.');
                });

                if (fndField.length > 0) {
                  v.set('selField', fndField[0]);
                }
              })();
            }
          } else {
            v.set('valueType', 'projectData');
          }
        });
      }

      return vars;
    }),

    _getField: function _getField(fieldId) {
      var fieldVal = this.get('textFields').filter(function (x) {
        return x.get('id') === fieldId;
      });
      if (fieldVal && fieldVal.length > 0) {
        return fieldVal[0];
      }
    },

    _getVariable: function _getVariable(vars, name) {
      var v = this.get('vars').filter(function (x) {
        return x.get('name') === name;
      });
      if (v && v.length > 0) {
        return v[0];
      } else {
        return null;
      }
    },

    _variableTypeUpdated: function _variableTypeUpdated(data, valType) {
      var v = this._getVariable(this.get('vars'), data.get('name'));

      v.set('valueType', valType.id);
      this.sendAction('variablesUpdated', this.get('vars'));
    },

    _variableValueUpdated: function _variableValueUpdated(data, newVal) {
      var v = this._getVariable(this.get('vars'), data.get('name'));
      v.set('valueRes', newVal.value);

      this.sendAction('variablesUpdated', this.get('vars'));
    }

  });
});