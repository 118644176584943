define("sheer/templates/components/schema-field-text", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "sheer/templates/components/schema-field-text.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "one-way-input", [], ["value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [2, 8], [2, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", ["update"], [], ["loc", [null, [3, 9], [3, 26]]], 0, 0], "autofocus", true, "name", ["subexpr", "@mut", [["get", "key", ["loc", [null, [5, 7], [5, 10]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "property.displayProperties.placeholder", ["loc", [null, [6, 14], [6, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-control", "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [8, 11], [8, 19]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [9, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});