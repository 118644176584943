define("sheer/components/template-editor/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 4
            },
            "end": {
              "line": 8,
              "column": 4
            }
          },
          "moduleName": "sheer/components/template-editor/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "fa fa-2x fa-eraser pull-right btn");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element13 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element13);
          return morphs;
        },
        statements: [["element", "action", ["deleteTemplate"], [], ["loc", [null, [7, 54], [7, 81]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 10
            },
            "end": {
              "line": 21,
              "column": 10
            }
          },
          "moduleName": "sheer/components/template-editor/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "one-way-input", [], ["class", "form-control col-md-7 col-xs-12", "value", ["subexpr", "@mut", [["get", "name", ["loc", [null, [19, 34], [19, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["subexpr", "mut", [["get", "name", ["loc", [null, [20, 48], [20, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 43], [20, 53]]], 0, 0]], [], ["loc", [null, [20, 35], [20, 54]]], 0, 0]], ["loc", [null, [18, 12], [20, 56]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 10
            },
            "end": {
              "line": 23,
              "column": 10
            }
          },
          "moduleName": "sheer/components/template-editor/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "form-control-static col-md-7 col-xs-12");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element12, 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element12, [2]), 0, 0);
          return morphs;
        },
        statements: [["content", "name", ["loc", [null, [22, 62], [22, 70]]], 0, 0, 0, 0], ["content", "versionText", ["loc", [null, [22, 74], [22, 89]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 6
            },
            "end": {
              "line": 62,
              "column": 6
            }
          },
          "moduleName": "sheer/components/template-editor/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "control-label col-md-1 col-sm-3 col-xs-12");
          var el3 = dom.createTextNode("Version");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-5 col-sm-6 col-xs-12");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "control-label col-md-1 col-sm-3 col-xs-12");
          var el3 = dom.createTextNode("Asset Type");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-5 col-sm-6 col-xs-12");
          var el3 = dom.createTextNode("\n\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-2");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          dom.setAttribute(el3, "class", "control-label");
          var el4 = dom.createTextNode(" Show all");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element11, [3]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element11, [5]), 3, 3);
          return morphs;
        },
        statements: [["inline", "one-way-select", [], ["class", "form-control col-md-7 col-xs-12", "value", ["subexpr", "@mut", [["get", "version", ["loc", [null, [38, 35], [38, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "versionOpts", ["loc", [null, [39, 37], [39, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["subexpr", "mut", [["get", "version", ["loc", [null, [40, 49], [40, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [40, 44], [40, 57]]], 0, 0]], [], ["loc", [null, [40, 36], [40, 58]]], 0, 0]], ["loc", [null, [37, 12], [40, 60]]], 0, 0], ["inline", "one-way-select", [], ["class", "form-control col-md-7 col-xs-12", "options", ["subexpr", "@mut", [["get", "typeOpts", ["loc", [null, [49, 37], [49, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "optionLabelPath", "label", "optionValuePath", "id", "value", ["subexpr", "@mut", [["get", "type", ["loc", [null, [52, 35], [52, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["get", "typeSelected", ["loc", [null, [53, 44], [53, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [53, 36], [53, 57]]], 0, 0]], ["loc", [null, [48, 12], [53, 59]]], 0, 0], ["inline", "one-way-checkbox", [], ["checked", ["subexpr", "@mut", [["get", "showAllTypes", ["loc", [null, [58, 39], [58, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", ["toggleAllTypes"], [], ["loc", [null, [58, 59], [58, 84]]], 0, 0]], ["loc", [null, [58, 12], [58, 86]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 70,
                    "column": 14
                  },
                  "end": {
                    "line": 79,
                    "column": 14
                  }
                },
                "moduleName": "sheer/components/template-editor/template.hbs"
              },
              isEmpty: false,
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "template-property-editor", [], ["propertyName", ["subexpr", "@mut", [["get", "subPropName", ["loc", [null, [71, 56], [71, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "propertyType", ["subexpr", "@mut", [["get", "subPropValue", ["loc", [null, [71, 81], [71, 93]]], 0, 0, 0, 0]], [], [], 0, 0], "templateId", ["subexpr", "@mut", [["get", "templateId", ["loc", [null, [72, 54], [72, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "propertyPath", ["subexpr", "concat", [["get", "propName", ["loc", [null, [73, 64], [73, 72]]], 0, 0, 0, 0], ".", ["get", "subPropName", ["loc", [null, [73, 77], [73, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [73, 56], [73, 89]]], 0, 0], "propertyValue", ["subexpr", "lookup-property-value", [["get", "documentTemplate", ["loc", [null, [74, 80], [74, 96]]], 0, 0, 0, 0], ["get", "propName", ["loc", [null, [74, 97], [74, 105]]], 0, 0, 0, 0], ["get", "subPropName", ["loc", [null, [74, 106], [74, 117]]], 0, 0, 0, 0]], [], ["loc", [null, [74, 57], [74, 118]]], 0, 0], "tags", ["subexpr", "@mut", [["get", "tags", ["loc", [null, [75, 48], [75, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "lookupTemplatesForType", ["subexpr", "@mut", [["get", "lookupTemplatesForType", ["loc", [null, [76, 66], [76, 88]]], 0, 0, 0, 0]], [], [], 0, 0], "dataChanged", ["subexpr", "action", ["propertyDataChanged"], [], ["loc", [null, [77, 55], [77, 85]]], 0, 0], "propertyClass", "form-control col-md-10 col-xs-12"], ["loc", [null, [71, 16], [78, 93]]], 0, 0]],
              locals: ["subPropName", "subPropValue"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 66,
                  "column": 10
                },
                "end": {
                  "line": 82,
                  "column": 10
                }
              },
              "moduleName": "sheer/components/template-editor/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h4");
              dom.setAttribute(el1, "class", "col-md-12");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "form-group");
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
              return morphs;
            },
            statements: [["content", "propName", ["loc", [null, [67, 34], [67, 46]]], 0, 0, 0, 0], ["block", "each-in", [["get", "propValue.properties", ["loc", [null, [70, 25], [70, 45]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [70, 14], [79, 26]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 10
                },
                "end": {
                  "line": 90,
                  "column": 10
                }
              },
              "moduleName": "sheer/components/template-editor/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "template-property-editor", [], ["propertyName", ["subexpr", "@mut", [["get", "propName", ["loc", [null, [83, 52], [83, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "propertyType", ["subexpr", "@mut", [["get", "propValue", ["loc", [null, [83, 74], [83, 83]]], 0, 0, 0, 0]], [], [], 0, 0], "templateId", ["subexpr", "@mut", [["get", "templateId", ["loc", [null, [83, 95], [83, 105]]], 0, 0, 0, 0]], [], [], 0, 0], "propertyPath", ["subexpr", "@mut", [["get", "propName", ["loc", [null, [84, 52], [84, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "propertyValue", ["subexpr", "lookup-property-value", [["get", "documentTemplate", ["loc", [null, [85, 76], [85, 92]]], 0, 0, 0, 0], ["get", "propName", ["loc", [null, [85, 93], [85, 101]]], 0, 0, 0, 0]], [], ["loc", [null, [85, 53], [85, 102]]], 0, 0], "lookupTemplatesForType", ["subexpr", "@mut", [["get", "lookupTemplatesForType", ["loc", [null, [86, 62], [86, 84]]], 0, 0, 0, 0]], [], [], 0, 0], "dataChanged", ["subexpr", "action", ["propertyDataChanged"], [], ["loc", [null, [87, 51], [87, 81]]], 0, 0], "tags", ["subexpr", "@mut", [["get", "tags", ["loc", [null, [88, 44], [88, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "propertyClass", "form-control col-md-10 col-xs-12"], ["loc", [null, [83, 12], [89, 89]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 8
              },
              "end": {
                "line": 94,
                "column": 8
              }
            },
            "moduleName": "sheer/components/template-editor/template.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "propValue.title", ["loc", [null, [66, 20], [66, 35]]], 0, 0, 0, 0], "section"], [], ["loc", [null, [66, 16], [66, 46]]], 0, 0]], [], 0, 1, ["loc", [null, [66, 10], [90, 17]]]]],
          locals: ["propName", "propValue"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 63,
              "column": 6
            },
            "end": {
              "line": 97,
              "column": 6
            }
          },
          "moduleName": "sheer/components/template-editor/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("hr");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["block", "each-in", [["get", "schemaProps", ["loc", [null, [65, 19], [65, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [65, 8], [94, 20]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 108,
                "column": 8
              },
              "end": {
                "line": 111,
                "column": 8
              }
            },
            "moduleName": "sheer/components/template-editor/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "btn btn-link");
            var el2 = dom.createTextNode("Extract Variables\n            for ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element9);
            morphs[1] = dom.createMorphAt(element9, 1, 1);
            return morphs;
          },
          statements: [["element", "action", [["subexpr", "action", ["popExtractDialog", ["get", "bn.blobId", ["loc", [null, [109, 75], [109, 84]]], 0, 0, 0, 0]], [], ["loc", [null, [109, 48], [109, 85]]], 0, 0]], [], ["loc", [null, [109, 39], [109, 87]]], 0, 0], ["content", "bn.name", ["loc", [null, [110, 16], [110, 27]]], 0, 0, 0, 0]],
          locals: ["bn"],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 114,
                  "column": 10
                },
                "end": {
                  "line": 125,
                  "column": 10
                }
              },
              "moduleName": "sheer/components/template-editor/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "template-variable-extraction-modal", [], ["blobs", ["subexpr", "@mut", [["get", "blobs", ["loc", [null, [115, 55], [115, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "blobId", ["subexpr", "@mut", [["get", "extBlobId", ["loc", [null, [116, 56], [116, 65]]], 0, 0, 0, 0]], [], [], 0, 0], "blobNames", ["subexpr", "@mut", [["get", "blobNames", ["loc", [null, [117, 59], [117, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "templateId", ["subexpr", "@mut", [["get", "templateId", ["loc", [null, [118, 60], [118, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "textFields", ["subexpr", "@mut", [["get", "fields", ["loc", [null, [119, 60], [119, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "refFields", ["subexpr", "@mut", [["get", "refTypeProps", ["loc", [null, [120, 59], [120, 71]]], 0, 0, 0, 0]], [], [], 0, 0], "refDefs", ["subexpr", "@mut", [["get", "refDefs", ["loc", [null, [121, 57], [121, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "cancel", ["subexpr", "action", ["closeExtractDialog"], [], ["loc", [null, [122, 56], [122, 85]]], 0, 0], "save", ["subexpr", "action", ["applyExtractedVariables"], [], ["loc", [null, [123, 54], [123, 88]]], 0, 0]], ["loc", [null, [115, 12], [124, 14]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 113,
                "column": 8
              },
              "end": {
                "line": 126,
                "column": 8
              }
            },
            "moduleName": "sheer/components/template-editor/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "modal-dialog", [], ["target", "#template_editor_panel"], 0, null, ["loc", [null, [114, 10], [125, 27]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 152,
                "column": 10
              },
              "end": {
                "line": 177,
                "column": 10
              }
            },
            "moduleName": "sheer/components/template-editor/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "list-group-item");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-md-2");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", " col-md-2 col-xs-12");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", " col-md-2 col-xs-12");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-md-4");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "clearfix");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var element7 = dom.childAt(element6, [1]);
            var element8 = dom.childAt(element7, [1]);
            var morphs = new Array(6);
            morphs[0] = dom.createElementMorph(element8);
            morphs[1] = dom.createMorphAt(element8, 1, 1);
            morphs[2] = dom.createMorphAt(element7, 3, 3);
            morphs[3] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
            morphs[4] = dom.createMorphAt(dom.childAt(element6, [5]), 1, 1);
            morphs[5] = dom.createMorphAt(dom.childAt(element6, [7]), 1, 1);
            return morphs;
          },
          statements: [["element", "action", [["subexpr", "action", ["deleteVariable", ["get", "v.id", ["loc", [null, [155, 53], [155, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [155, 28], [155, 58]]], 0, 0]], [], ["loc", [null, [155, 19], [155, 60]]], 0, 0], ["inline", "fa-icon", ["times-circle"], ["style", "margin-left: -15px; color: rgb(192,22,22); cursor: pointer;"], ["loc", [null, [156, 18], [156, 112]]], 0, 0], ["content", "v.name", ["loc", [null, [158, 16], [158, 26]]], 0, 0, 0, 0], ["content", "v.applicator", ["loc", [null, [162, 16], [162, 32]]], 0, 0, 0, 0], ["content", "v.applicatorData", ["loc", [null, [166, 16], [166, 36]]], 0, 0, 0, 0], ["inline", "light-table/cells/value-data-cell", [], ["valueType", ["subexpr", "@mut", [["get", "v.mapping.dataRefSource", ["loc", [null, [170, 62], [170, 85]]], 0, 0, 0, 0]], [], [], 0, 0], "fields", ["subexpr", "@mut", [["get", "fields", ["loc", [null, [170, 93], [170, 99]]], 0, 0, 0, 0]], [], [], 0, 0], "variable", ["subexpr", "@mut", [["get", "v", ["loc", [null, [171, 61], [171, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "refTypeProps", ["subexpr", "@mut", [["get", "refTypeProps", ["loc", [null, [171, 76], [171, 88]]], 0, 0, 0, 0]], [], [], 0, 0], "refDefs", ["subexpr", "@mut", [["get", "refDefs", ["loc", [null, [171, 97], [171, 104]]], 0, 0, 0, 0]], [], [], 0, 0], "varChanged", ["subexpr", "action", [["get", "varChanged", ["loc", [null, [172, 71], [172, 81]]], 0, 0, 0, 0]], [], ["loc", [null, [172, 63], [172, 82]]], 0, 0]], ["loc", [null, [170, 16], [172, 84]]], 0, 0]],
          locals: ["v"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 99,
              "column": 6
            },
            "end": {
              "line": 201,
              "column": 6
            }
          },
          "moduleName": "sheer/components/template-editor/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "from-group col-md-12");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("hr");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "clearfix");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          var el2 = dom.createTextNode("Variables");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "clearfix");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "list-group");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "list-group-heading");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment(" Created variables go here");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-md-2 list-group-heading");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("b");
          var el5 = dom.createTextNode("Name");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", " col-md-2 list-group-heading");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("b");
          var el5 = dom.createTextNode("Applicator");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", " col-md-2 list-group-heading");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("b");
          var el5 = dom.createTextNode("Appl. Data");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "col-md-4 list-group-heading");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("b");
          var el5 = dom.createTextNode("Mapping");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "clearfix");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-md-3");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", " col-md-3 col-xs-12");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-md-3");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-md-3");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-default");
          var el3 = dom.createTextNode("Add Variable");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [21, 1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 9, 9, contextualElement);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [13]), 3, 3);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [15]), 1, 1);
          morphs[4] = dom.createMorphAt(dom.childAt(fragment, [17]), 1, 1);
          morphs[5] = dom.createMorphAt(dom.childAt(fragment, [19]), 1, 1);
          morphs[6] = dom.createElementMorph(element10);
          return morphs;
        },
        statements: [["block", "each", [["get", "blobNames", ["loc", [null, [108, 16], [108, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [108, 8], [111, 17]]]], ["block", "if", [["get", "isShowingExtractDialog", ["loc", [null, [113, 14], [113, 36]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [113, 8], [126, 15]]]], ["block", "each", [["get", "variablesWithMapping", ["loc", [null, [152, 18], [152, 38]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [152, 10], [177, 19]]]], ["inline", "one-way-input", [], ["name", "newvarname", "class", "form-control", "update", ["subexpr", "action", [["subexpr", "mut", [["get", "newvarname", ["loc", [null, [181, 85], [181, 95]]], 0, 0, 0, 0]], [], ["loc", [null, [181, 80], [181, 96]]], 0, 0]], [], ["loc", [null, [181, 72], [181, 97]]], 0, 0]], ["loc", [null, [181, 10], [181, 99]]], 0, 0], ["inline", "one-way-select", [], ["name", "newvarapplicator", "class", "form-control", "options", ["subexpr", "@mut", [["get", "applicators", ["loc", [null, [186, 35], [186, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "optionLabelPath", "name", "optionValuePath", "id", "update", ["subexpr", "action", [["subexpr", "mut", [["get", "newvarappl", ["loc", [null, [189, 47], [189, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [189, 42], [189, 58]]], 0, 0]], [], ["loc", [null, [189, 34], [189, 59]]], 0, 0]], ["loc", [null, [185, 10], [190, 12]]], 0, 0], ["inline", "one-way-input", [], ["name", "newvarapplicatordata", "class", "form-control", "update", ["subexpr", "action", [["subexpr", "mut", [["get", "newvarappldata", ["loc", [null, [195, 46], [195, 60]]], 0, 0, 0, 0]], [], ["loc", [null, [195, 41], [195, 61]]], 0, 0]], [], ["loc", [null, [195, 33], [195, 62]]], 0, 0]], ["loc", [null, [194, 10], [195, 64]]], 0, 0], ["element", "action", [["subexpr", "action", ["addVariable"], [], ["loc", [null, [199, 27], [199, 49]]], 0, 0]], [], ["loc", [null, [199, 18], [199, 51]]], 0, 0]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child6 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 215,
                  "column": 14
                },
                "end": {
                  "line": 217,
                  "column": 14
                }
              },
              "moduleName": "sheer/components/template-editor/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "fa-icon", ["check"], [], ["loc", [null, [216, 16], [216, 35]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 218,
                  "column": 14
                },
                "end": {
                  "line": 220,
                  "column": 14
                }
              },
              "moduleName": "sheer/components/template-editor/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "fa-icon", ["check"], [], ["loc", [null, [219, 16], [219, 35]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 213,
                "column": 10
              },
              "end": {
                "line": 222,
                "column": 10
              }
            },
            "moduleName": "sheer/components/template-editor/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "col-md-2");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element2, 1, 1);
            morphs[1] = dom.createMorphAt(element2, 2, 2);
            return morphs;
          },
          statements: [["block", "if", [["get", "templateSaved", ["loc", [null, [215, 20], [215, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [215, 14], [217, 21]]]], ["block", "if", [["get", "previewSaved", ["loc", [null, [218, 20], [218, 32]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [218, 14], [220, 21]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 222,
                "column": 10
              },
              "end": {
                "line": 226,
                "column": 10
              }
            },
            "moduleName": "sheer/components/template-editor/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "col-md-9");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.setAttribute(el2, "class", "validationFail");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
            return morphs;
          },
          statements: [["content", "saveStatus", ["loc", [null, [224, 43], [224, 57]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 208,
              "column": 8
            },
            "end": {
              "line": 227,
              "column": 8
            }
          },
          "moduleName": "sheer/components/template-editor/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-md-3");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-primary");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-secondary");
          var el3 = dom.createTextNode("CANCEL");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element3, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element4);
          morphs[1] = dom.createMorphAt(element4, 0, 0);
          morphs[2] = dom.createElementMorph(element5);
          morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["element", "action", ["create"], [], ["loc", [null, [210, 20], [210, 39]]], 0, 0], ["inline", "if", [["get", "isEdit", ["loc", [null, [210, 69], [210, 75]]], 0, 0, 0, 0], "UPDATE", "CREATE"], [], ["loc", [null, [210, 64], [210, 95]]], 0, 0], ["element", "action", [["subexpr", "action", [["get", "closeEditor", ["loc", [null, [211, 37], [211, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [211, 29], [211, 49]]], 0, 0]], [], ["loc", [null, [211, 20], [211, 51]]], 0, 0], ["block", "if", [["subexpr", "and", [["get", "templateSaved", ["loc", [null, [213, 21], [213, 34]]], 0, 0, 0, 0], ["get", "previewSaved", ["loc", [null, [213, 35], [213, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [213, 16], [213, 48]]], 0, 0]], [], 0, 1, ["loc", [null, [213, 10], [226, 17]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child7 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 233,
                "column": 12
              },
              "end": {
                "line": 235,
                "column": 12
              }
            },
            "moduleName": "sheer/components/template-editor/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "fa-icon", ["check"], [], ["loc", [null, [234, 14], [234, 33]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 227,
              "column": 8
            },
            "end": {
              "line": 238,
              "column": 8
            }
          },
          "moduleName": "sheer/components/template-editor/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-md-3");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-primary disabled");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn-secondary disabled");
          var el3 = dom.createTextNode("CANCEL");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-md-2");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          morphs[2] = dom.createMorphAt(element1, 3, 3);
          return morphs;
        },
        statements: [["inline", "if", [["get", "isEdit", ["loc", [null, [229, 58], [229, 64]]], 0, 0, 0, 0], "UPDATE", "CREATE"], [], ["loc", [null, [229, 53], [229, 84]]], 0, 0], ["block", "if", [["get", "templateSaved", ["loc", [null, [233, 18], [233, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [233, 12], [235, 19]]]], ["inline", "fa-icon", ["spinner"], ["spin", true], ["loc", [null, [236, 12], [236, 43]]], 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    var child8 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 243,
              "column": 4
            },
            "end": {
              "line": 246,
              "column": 4
            }
          },
          "moduleName": "sheer/components/template-editor/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      Preview:");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "previewImg");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'src');
          return morphs;
        },
        statements: [["attribute", "src", ["concat", [["get", "previewUrl", ["loc", [null, [245, 37], [245, 47]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 251,
            "column": 0
          }
        },
        "moduleName": "sheer/components/template-editor/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "x_panel");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "x_title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "clearfix");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "x_content");
        dom.setAttribute(el2, "id", "template_editor_panel");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        dom.setAttribute(el3, "class", "form-horizontal form-label-left");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "form-group");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "class", "control-label col-md-1 col-sm-3 col-xs-12");
        var el6 = dom.createTextNode("Name");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-md-5 col-sm-6 col-xs-12");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "class", "control-label col-md-1 col-sm-3 col-xs-12");
        var el6 = dom.createTextNode("Status");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-md-3 col-sm-6 col-xs-12");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "from-group col-md-12");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("hr");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "form-group col-md-12");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element14 = dom.childAt(fragment, [0]);
        var element15 = dom.childAt(element14, [1]);
        var element16 = dom.childAt(element14, [3]);
        var element17 = dom.childAt(element16, [1]);
        var element18 = dom.childAt(element17, [1]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(dom.childAt(element15, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element15, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element18, [3]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element18, [7]), 1, 1);
        morphs[4] = dom.createMorphAt(element17, 3, 3);
        morphs[5] = dom.createMorphAt(element17, 4, 4);
        morphs[6] = dom.createMorphAt(element17, 6, 6);
        morphs[7] = dom.createMorphAt(dom.childAt(element17, [10]), 1, 1);
        morphs[8] = dom.createMorphAt(element16, 3, 3);
        morphs[9] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["content", "title", ["loc", [null, [4, 8], [4, 17]]], 0, 0, 0, 0], ["block", "if", [["get", "isEdit", ["loc", [null, [6, 10], [6, 16]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [6, 4], [8, 11]]]], ["block", "if", [["subexpr", "not", [["get", "isEdit", ["loc", [null, [17, 21], [17, 27]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 16], [17, 28]]], 0, 0]], [], 1, 2, ["loc", [null, [17, 10], [23, 17]]]], ["inline", "one-way-select", [], ["class", "form-control col-md-7 col-xs-12", "value", ["subexpr", "@mut", [["get", "status", ["loc", [null, [28, 33], [28, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "statusOpts", ["loc", [null, [29, 35], [29, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["subexpr", "mut", [["get", "status", ["loc", [null, [30, 47], [30, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [30, 42], [30, 54]]], 0, 0]], [], ["loc", [null, [30, 34], [30, 55]]], 0, 0]], ["loc", [null, [27, 10], [30, 57]]], 0, 0], ["block", "if", [["subexpr", "not", [["get", "magicMode", ["loc", [null, [33, 17], [33, 26]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 12], [33, 27]]], 0, 0]], [], 3, null, ["loc", [null, [33, 6], [62, 13]]]], ["block", "if", [["get", "type", ["loc", [null, [63, 12], [63, 16]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [63, 6], [97, 13]]]], ["block", "if", [["subexpr", "not", [["get", "magicMode", ["loc", [null, [99, 17], [99, 26]]], 0, 0, 0, 0]], [], ["loc", [null, [99, 12], [99, 27]]], 0, 0]], [], 5, null, ["loc", [null, [99, 6], [201, 13]]]], ["block", "if", [["get", "saveTask.isIdle", ["loc", [null, [208, 14], [208, 29]]], 0, 0, 0, 0]], [], 6, 7, ["loc", [null, [208, 8], [238, 15]]]], ["block", "if", [["get", "previewUrl", ["loc", [null, [243, 10], [243, 20]]], 0, 0, 0, 0]], [], 8, null, ["loc", [null, [243, 4], [246, 11]]]], ["content", "yield", ["loc", [null, [250, 0], [250, 9]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
    };
  })());
});