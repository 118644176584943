define('sheer/controllers/config/asset-type', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {

    isExpanded: false,
    toggleExpanded: function toggleExpanded() {
      this.toggleProperty('isExpanded');
    },
    actions: {

      'delete': function _delete(id) {
        var _this = this;

        var delone = this.get('store').peekRecord('asset_type', id);
        this.get('store').deleteRecord(delone);
        delone.save().then(function () {
          _this.transitionToRoute('config.asset-types');
        });
      },

      create: function create(data) {
        var _this2 = this;

        var isEdit = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

        var newone = null;

        if (isEdit) {
          var editone = this.get('store').peekRecord('asset_type', data.id);

          if (editone.get('version') === data.version) {
            editone.set('name', data.name);
            editone.set('metadata', data.metadata);
            editone.set('documentSchema', data.documentSchema);

            newone = editone;
          }
        }

        if (newone == null) {
          newone = this.get('store').createRecord('asset_type', {
            name: data.name,
            version: data.version,
            metadata: data.metadata,
            documentSchema: data.documentSchema
          });
        }

        newone.save().then(function (saved) {
          _this2.transitionToRoute('config.asset-type', saved.get('id'));
        });
      }

    }
  });
});