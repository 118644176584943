define('sheer/components/asset-template-card/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    thumbnailUrl: computed('previewAssets.[]', 'template.thumbnailUrl', function () {
      var tmplThumbUrl = this.get('template.thumbnailUrl');
      var previewAssets = this.get('previewAssets');
      var previewObj = previewAssets && previewAssets.get(parseInt(this.get('template.id')));
      if (tmplThumbUrl) {
        return tmplThumbUrl;
      } else if (previewAssets && previewObj) {
        return previewObj.get('thumbnailUrl'); // Because of the int in the middle it isn't obvious how to get this into a single get
      } else {
          return null;
        }
    }),

    previewImageUrl: computed('previewAssets.[]', 'template.previewUrl', function () {
      var tmplPrevUrl = this.get('template.previewUrl');
      var previewAssets = this.get('previewAssets');
      var previewObj = previewAssets && previewAssets.get(parseInt(this.get('template.id')));
      if (tmplPrevUrl) {
        return tmplPrevUrl;
      } else if (previewAssets && previewObj) {
        return previewObj.get('previewUrl'); // Because of the int in the middle it isn't obvious how to get this into a single get
      } else {
          return null;
        }
    })

  });
});