define('sheer/components/light-table/cells/asset-ref-selector-cell', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    NON_ATTRIBUTE_BOUND_PROPS: ['table', 'column', 'row', 'rawvalue'],
    classNames: 'table-cell-spaced',

    _flatRefFields: function _flatRefFields(field) {
      return field && {
        label: field.refDef.get('name') + ' - ' + field.name,
        id: field.path,
        refDef: field.refDef,
        field: field
      };
    },

    _flatObjRefFields: function _flatObjRefFields(field) {
      return field && {
        label: field.get('name'),
        id: field.get('name'),
        refDef: field,
        field: field
      };
    },

    _setValueRes: function _setValueRes(field) {

      var hintObj = {};
      if (this.get('selObj')) {
        var fieldId = get(field, 'name') || get(field, 'id');
        hintObj = { value: fieldId, id: fieldId, type: 'assetObjRef' };
      } else {
        var fieldId = field.path || field.id || field.get('id');
        var dep = field.refDef.name;
        if (typeof dep !== 'string') {
          dep = field.refDef.get('name');
        }
        var path = dep + '.' + fieldId;

        hintObj = { value: path, id: path, type: 'assetRef' };
      }
      this.sendAction('update', hintObj);
      return hintObj;
    },

    init: function init() {
      this._super.apply(this, arguments);
    },

    selectedField: computed('row.selField', 'row.mapping.[]', 'table.refFields', 'table.refFields.[]', 'table.refDefs', 'table.refDefs.[]', function () {

      var mapping = this.get('row.mapping');
      var selField = this.get('row.selField');

      if (mapping && !selField) {
        if (this.get('selObj')) {
          var refDefs = this.get('table.refDefs');
          if (refDefs) {
            var foundField = refDefs.filter(function (x) {
              return get(x, 'name') === mapping.dataRefId;
            });
            this.set('row.selField', foundField && foundField.length > 0 ? foundField[0] : null);
          }
        } else {
          var refFields = this.get('table.refFields');
          if (refFields) {
            var foundField = refFields.filter(function (x) {
              return x.refDef.get('name') + '.' + x.path === mapping.dataRefId;
            });
            selField = foundField && foundField.length > 0 ? foundField[0] : null;
            this.set('row.selField', selField);
          }
        }
      }

      if (selField) {
        this._setValueRes(selField);
      }

      if (this.get('selObj')) {
        return this._flatObjRefFields(this.get('row.selField'));
      } else {
        return this._flatRefFields(this.get('row.selField'));
      }
    }),

    valSelected: function valSelected(val) {
      this.set('row.selField', val.field);
      this.set('row.mapping', this._setValueRes(val));
    },

    focus: function focus() {
      this.set('isFocussed', true);
    },

    blur: function blur() {
      this.set('isFocussed', false);
    },

    fieldOptions: computed('table.refFields', 'isFocussed', function () {
      if (this.get('isFocussed')) {
        var fields = [];
        if (this.get('selObj')) {
          var vals = this.get('table.refDefs');
          if (vals) {
            fields = vals.map(this._flatObjRefFields);
          }
        } else {
          var vals = this.get('table.refFields');
          if (vals) {
            fields = vals.filter(function (f) {
              return f.type === 'file';
            }).map(this._flatRefFields);
          }
        }
        return fields;
      } else {
        return [];
      }
    })

  });
});