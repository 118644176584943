define("sheer/templates/components/light-table/cells/value-data-cell", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "sheer/templates/components/light-table/cells/value-data-cell.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "light-table/cells/extractor-map-cell", [], ["table", ["subexpr", "@mut", [["get", "table", ["loc", [null, [2, 47], [2, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "row", ["subexpr", "@mut", [["get", "row", ["loc", [null, [2, 57], [2, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["get", "updateFieldSelector", ["loc", [null, [2, 76], [2, 95]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 68], [2, 96]]], 0, 0]], ["loc", [null, [2, 2], [2, 98]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "sheer/templates/components/light-table/cells/value-data-cell.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "one-way-input", [], ["value", ["subexpr", "@mut", [["get", "row.value", ["loc", [null, [4, 24], [4, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["get", "updateStaticValue", ["loc", [null, [4, 49], [4, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 41], [4, 67]]], 0, 0]], ["loc", [null, [4, 2], [4, 69]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 9
          }
        },
        "moduleName": "sheer/templates/components/light-table/cells/value-data-cell.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "isSelect", ["loc", [null, [1, 6], [1, 14]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [5, 7]]]], ["content", "yield", ["loc", [null, [8, 0], [8, 9]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});