define('sheer/routes/config/asset-type/asset-template', ['exports', 'ember'], function (exports, _ember) {
  var RSVP = _ember['default'].RSVP;
  var Route = _ember['default'].Route;
  var service = _ember['default'].inject.service;
  exports['default'] = Route.extend({
    session: service('session'),
    renderTemplate: function renderTemplate(controller) {

      this.render();

      controller.set('isTmplActive', true);

      this.render('asset-menu', {
        into: 'application',
        outlet: 'assetMenu'
      });
    },

    setupController: function setupController(controller, models) {
      this._super(controller, models);

      controller.set('types', models.types);
      controller.set('type', models.type);
      controller.set('templates', models.templates);
      controller.set('fields', models.fields);
      var template = models.template;

      var d = template.get('documentTemplate');
      template.set('documentTemplateObj', JSON.parse(d));
      template.set('isSchemaParsed', true);
      controller.set('tags', models.tags);

      controller.set('template', template);
      controller.set('applicators', models.applicators);
      controller.set('variables', []);
    },

    model: function model(params) {
      return RSVP.hash({
        templates: this.get('store').findAll('asset-template'),
        template: this.get('store').findRecord('asset-template', params.assetTemplateId, { reload: true }),
        types: this.get('store').findAll('asset-type'),
        type: this.get('store').findRecord('asset-type', params.assettypeId, { reload: true }),
        fields: this.get('store').findAll('field'),
        tags: this.get('store').findAll('tag')
      });
    }
  });
});