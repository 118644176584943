define('sheer/helpers/form-elem-for-schema-prop', ['exports', 'ember'], function (exports, _ember) {
  exports.formElemForSchemaProp = formElemForSchemaProp;
  var assert = _ember['default'].assert;
  var Helper = _ember['default'].Helper;

  function formElemForSchemaProp(params /*, hash*/) {
    assert('Helper takes exactlly 1 param', params.length === 1);
    switch (params[0].title) {
      case 'color':
        return 'template-property-editors/color-picker-text';
      case 'file':
        return 'template-property-editors/pretty-file-uploader';
      case 'namedfiles':
        return 'template-property-editors/named-files';
      case 'fontsettings':
        return 'template-property-editors/font-styler';
      case 'variableHints':
        return 'template-property-editors/variable-hints';
      case 'dataMapping':
        return 'template-property-editors/data-mapping';
      case 'BoostExcludeList':
        return 'template-property-editors/boost-exclude-list';
      case 'FamilyOrGeneric':
        return 'template-property-editors/family-or-generic';
      case 'location':
        return 'template-property-editors/map';
      case 'PickList':
        return 'template-property-editors/pick-list';
      case 'TemplateList':
        return 'template-property-editors/template-list';
      case null:
        return 'template-property-editors/simple';
      default:
        console.log('Unknown template property ' + params[0].title);
        return 'template-property-editors/simple';
    }
  }

  exports['default'] = Helper.helper(formElemForSchemaProp);
});