define('sheer/components/template-variable-extraction-modal', ['exports', 'ember', 'ember-light-table', 'sheer/config/environment'], function (exports, _ember, _emberLightTable, _sheerConfigEnvironment) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var $ = _ember['default'].$;
  exports['default'] = Component.extend({
    canLoadMore: true,
    isLoading: false,
    extractions: [],
    columns: computed(function () {
      return [{ label: '', width: '20px', valuePath: 'include', cellComponent: 'light-table/cells/one-way-checkbox-cell' }, { label: 'VarName', valuePath: 'variableHint', width: '150px', cellComponent: 'light-table/cells/one-way-input-cell' }, { label: 'Name', valuePath: 'applicatorName', sortable: true, width: '150px' }, { label: 'Data', valuePath: 'applicatorData', width: '175px' }, { label: 'Default', valuePath: 'default', width: '200px', cellComponent: 'light-table/cells/one-way-input-cell' }, { label: 'Info Map', valuePath: 'mapping', width: '200px', cellComponent: 'light-table/cells/extractor-map-cell' }];
    }),

    table: computed('extractions', function () {
      var table = new _emberLightTable['default'](this.get('columns'), this.get('extractions'), { enableSync: true });
      table.set('textFields', this.get('textFields'));
      table.set('refFields', this.get('refFields'));
      table.set('refDefs', this.get('refDefs'));
      return table;
    }),

    _camelKeys: function _camelKeys(obj) {
      var newobj = {};
      for (var k in obj) {
        if (obj.hasOwnProperty(k)) {
          var key = k.camelize();
          key = key === 'variableType' ? 'type' : key; // Line this up with variables (which just use type) for compatibility with asset designer
          newobj[key] = obj[k];
        }
      }
      return newobj;
    },

    fetchRecords: function fetchRecords() {
      var _this = this;

      this.set('isLoading', true);
      $.getJSON(_sheerConfigEnvironment['default'].host + '/applicator_suggestions?blobId=' + this.get('blobId')).then(function (records) {
        _this.get('extractions').clear();
        _this.get('extractions').pushObjects(records.toArray().map(function (x) {
          return _this._camelKeys(x.applicator_suggestion);
        })); // jscs:ignore requireCamelCaseOrUpperCaseIdentifiers
        _this.set('isLoading', false);
        _this.set('canLoadMore', false);
      });
    },

    actions: {
      applyExtractions: function applyExtractions() {
        this.sendAction('save', this.get('extractions').filter(function (x) {
          return x.include;
        }));
      },
      onScrolledToBottom: function onScrolledToBottom() {
        if (this.get('canLoadMore')) {
          this.fetchRecords();
        }
      }
    }

  });
});