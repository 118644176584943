define('sheer/components/light-table/cells/one-way-checkbox-cell', ['exports', 'ember', 'ember-one-way-controls/components/one-way-checkbox'], function (exports, _ember, _emberOneWayControlsComponentsOneWayCheckbox) {
  var set = _ember['default'].set;

  var OneWayCheckBoxCell = _emberOneWayControlsComponentsOneWayCheckbox['default'].extend({
    NON_ATTRIBUTE_BOUND_PROPS: ['table', 'column', 'row', 'rawvalue'],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('update', function (x) {
        var data = _this.get('row').content;
        var col = _this.get('column').valuePath;
        set(data, col, x);
      });
    }
  });

  exports['default'] = OneWayCheckBoxCell;
});