define('sheer/controllers/asset-designer', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'ember-local-storage', 'ember-json-schema-document/models/schema'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin, _emberLocalStorage, _emberJsonSchemaDocumentModelsSchema) {
  var RSVP = _ember['default'].RSVP;
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  exports['default'] = Controller.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    options: (0, _emberLocalStorage.storageFor)('assetBuilderOptions'),

    labeledTypes: computed('types', function () {
      return this.get('types').map(function (typ) {
        typ.set('label', typ.get('name') + ' - ' + typ.get('version'));
        return typ;
      });
    }),

    version: 0,
    versionOpts: computed('model', function () {

      var ver = 0;
      var model = this.get('model');
      var template = model && model.template;
      if (template) {
        ver = template.get('version');
      }

      return Array.from(new Array(10), function (x, i) {
        return i + ver;
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this._updateData();
    },
    _updateData: function _updateData() {
      var _this = this;

      var selTypeId = this.get('options.selTypeId');
      var selTemplateId = this.get('options.selTemplateId');
      var selProjectId = this.get('options.selProjectId');

      RSVP.hash({
        templates: this.get('store').findAll('asset-template'),
        selType: selTypeId && this.get('store').findRecord('asset-type', selTypeId, { reload: true }),
        selTemplate: selTemplateId && this.get('store').findRecord('asset-template', selTemplateId),
        selProject: selProjectId && this.get('store').findRecord('project', selProjectId)
      }).then(function (res) {
        _this.set('templates', res.templates);

        if (res.selProject) {
          _this.projectSelected(res.selProject, false);
        }
        if (res.selType) {
          _this.typeSelected(res.selType);

          _this.set('refDefs', res.selType.get('assetRefDefs'));
          _this.get('refTypeService').loadRefsSchemas(_this.get('types'), res.selType).then(function (x) {
            var res = [];
            x.forEach(function (a) {
              res = res.concat(a);
            });

            _this.set('refTypeProps', res);
          });
        }

        if (res.selTemplate) {
          if (!res.selType || res.selType.get('id') === res.selTemplate.get('assetType.id')) {
            _this.templateSelected(res.selTemplate);
          } else {
            _this.set('options.selTemplateId', null);
          }
        }
      });
    },
    projectSelected: function projectSelected(project) {
      var _this2 = this;

      var updateAfter = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

      this.set('selProject', project);
      this.set('options.selProjectId', project.get('id'));

      this.get('store').query('field', { 'projectId': project.get('id') }).then(function (data) {
        _this2.set('answers', data.map(function (x) {
          return { 'field': { 'id': x.get('id') }, 'answerVal': x.get('answerVal') };
        }).filter(function (x) {
          return x.answerVal;
        }));
      });

      if (updateAfter) {
        this._updateData();
      }
    },
    typeSelected: function typeSelected(type) {
      var _this3 = this;

      var templates = this.get('templates');
      this.set('templates', templates.filter(function (x) {
        return x.get('assetType.id') === type.id;
      }));

      // Now let's make sure we load the subtypes
      var depTypes = type.get('assetRefDefs');

      var subTypeObj = {};
      depTypes.forEach(function (t) {
        var refId = t.get('refAssetType.id');
        var inStore = _this3.get('store').peekRecord('asset-type', refId);
        if (!inStore || inStore.get('documentSchema') == null) {
          subTypeObj[refId] = _this3.get('store').findRecord('asset-type', refId, { reload: true });
        }
      });

      RSVP.hash(subTypeObj).then(function () {
        _this3.set('selType', type);
        _this3.set('options.selTypeId', type.get('id'));
      });
    },
    templateSelected: function templateSelected(tmpl) {
      var _this4 = this;

      this.get('store').findRecord('asset-template', tmpl.get('id'), { reload: true }).then(function (tmpl) {
        _this4.set('selTemplate', tmpl);
        _this4.set('options.selTemplateId', tmpl.get('id'));
      });
    },

    varsUpdated: function varsUpdated(vars) {
      this.set('vars', vars);
    },

    refSelected: function refSelected(ref, val) {
      var refs = this.get('refs') || {};

      refs[ref] = val;

      this.set('refs', refs);
    },

    refTypeService: inject.service('ref-types'),

    createAsset: function createAsset() {
      var _this5 = this;

      var newone = this.get('store').createRecord('asset', {
        name: this.get('name'),
        version: this.get('version') || '0',
        document: this.get('selTemplate.documentTemplate'),
        projectId: this.get('selProject.id')
      });

      newone.set('assetType', this.get('selType'));
      newone.set('assetTemplate', this.get('selTemplate'));

      var vars = this.get('vars');

      if (vars) {
        vars.forEach(function (r) {
          var newVar = _this5.get('store').createRecord('variable', {
            name: r.get('name'),
            'type': r.get('valueType') || 'projectData',
            'applicator': r.get('applicator'),
            'applicatorData': r.get('applicatorData'),
            'value': r.get('valueRes') || ''
          });

          newone.get('variables').pushObject(newVar);
        });
      }

      var refs = this.get('refs');
      if (refs) {
        for (var k in refs) {
          if (refs.hasOwnProperty(k)) {
            var r = refs[k];
            var newRef = this.get('store').createRecord('asset-ref', {
              name: k,
              refAsset: { 'id': r.get('id') }
            });

            newone.get('assetRefs').pushObject(newRef);
          }
        }
      }

      newone.save().then(function (arg) {
        _this5.set('savedAssetName', arg.get('name'));
        _this5.set('savedAssetId', arg.id);
      });
    }
  });
});