define('sheer/adapters/application', ['exports', 'ember', 'ember-data', 'sheer/config/environment'], function (exports, _ember, _emberData, _sheerConfigEnvironment) {
  var underscore = _ember['default'].String.underscore;
  var Inflector = _ember['default'].Inflector;
  var JSONAPIAdapter = _emberData['default'].JSONAPIAdapter;

  Inflector.inflector.uncountable('asset-type');
  Inflector.inflector.uncountable('asset-template');
  Inflector.inflector.uncountable('asset');
  Inflector.inflector.uncountable('asset-type-metadata');

  exports['default'] = JSONAPIAdapter.extend({
    host: _sheerConfigEnvironment['default'].host,

    pathForType: function pathForType(modelName) {
      if (modelName === 'variable-applicator') {
        return 'asset_template/variable_applicator';
      }
      return underscore(modelName);
    },

    urlForQueryRecord: function urlForQueryRecord(obj, modelName) {
      var id = obj.id;

      return this._buildURL(modelName, id);
    },

    urlForCreateRecord: function urlForCreateRecord(modelName) {
      if (modelName === 'asset') {
        return _sheerConfigEnvironment['default'].host + '/compose/asset';
      } else {
        return this._super.apply(this, arguments);
      }
    }

  });
});