define("sheer/templates/components/mini-login-form", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 0
            },
            "end": {
              "line": 6,
              "column": 0
            }
          },
          "moduleName": "sheer/templates/components/mini-login-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "errorMessage", ["loc", [null, [5, 2], [5, 18]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "sheer/templates/components/mini-login-form.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "btn btn-default pull-right");
        var el2 = dom.createTextNode("Login");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [4]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createElementMorph(element0);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "one-way-input", [], ["class", "form-control", "id", "identification", "placeholder", "Username", "value", ["subexpr", "@mut", [["get", "username", ["loc", [null, [1, 86], [1, 94]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["subexpr", "mut", [["get", "username", ["loc", [null, [1, 115], [1, 123]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 110], [1, 124]]], 0, 0]], [], ["loc", [null, [1, 102], [1, 125]]], 0, 0]], ["loc", [null, [1, 0], [1, 127]]], 0, 0], ["inline", "one-way-input", [], ["class", "form-control", "type", "password", "id", "password", "placeholder", "Password", "value", ["subexpr", "@mut", [["get", "password", ["loc", [null, [2, 96], [2, 104]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["subexpr", "mut", [["get", "password", ["loc", [null, [2, 125], [2, 133]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 120], [2, 134]]], 0, 0]], [], ["loc", [null, [2, 112], [2, 135]]], 0, 0]], ["loc", [null, [2, 0], [2, 137]]], 0, 0], ["element", "action", ["login", ["get", "username", ["loc", [null, [3, 61], [3, 69]]], 0, 0, 0, 0], ["get", "password", ["loc", [null, [3, 70], [3, 78]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 43], [3, 80]]], 0, 0], ["block", "if", [["get", "errorMessage", ["loc", [null, [4, 6], [4, 18]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 0], [6, 7]]]], ["content", "yield", ["loc", [null, [8, 0], [8, 9]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});