define("sheer/components/asset-menu/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 14
              },
              "end": {
                "line": 18,
                "column": 14
              }
            },
            "moduleName": "sheer/components/asset-menu/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "active");
            var el2 = dom.createElement("a");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 0, 0);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["content", "type.name", ["loc", [null, [14, 38], [14, 51]]], 0, 0, 0, 0], ["inline", "version-nested-menu", [], ["title", "Asset Templates", "isActive", ["subexpr", "@mut", [["get", "isTmplActive", ["loc", [null, [15, 71], [15, 83]]], 0, 0, 0, 0]], [], [], 0, 0], "isExpanded", ["subexpr", "@mut", [["get", "isExpanded", ["loc", [null, [15, 95], [15, 105]]], 0, 0, 0, 0]], [], [], 0, 0], "items", ["subexpr", "@mut", [["get", "templates", ["loc", [null, [16, 44], [16, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "menuContext", ["subexpr", "@mut", [["get", "type", ["loc", [null, [16, 66], [16, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "menuContextField", "assetType", "topLink", "config.asset-type.asset-templates", "subLink", "config.asset-type.asset-template"], ["loc", [null, [15, 16], [17, 127]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 24,
              "column": 0
            }
          },
          "moduleName": "sheer/components/asset-menu/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "sidebar-menu");
          dom.setAttribute(el1, "class", "main_menu_side hidden-print main_menu");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "menu_section");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Asset Configurations\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          dom.setAttribute(el3, "class", "nav side-menu");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [1, 1]);
          var element2 = dom.childAt(element0, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createElementMorph(element1);
          morphs[2] = dom.createMorphAt(element2, 1, 1);
          morphs[3] = dom.createMorphAt(element2, 3, 3);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["fa-pull-right btn fa ", ["subexpr", "if", [["get", "isExpanded", ["loc", [null, [5, 53], [5, 63]]], 0, 0, 0, 0], "fa-chevron-down", "fa-chevron-up"], [], ["loc", [null, [5, 48], [5, 99]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", [["subexpr", "action", [["get", "toggleExpanded", ["loc", [null, [6, 33], [6, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 25], [6, 48]]], 0, 0]], [], ["loc", [null, [6, 16], [6, 50]]], 0, 0], ["inline", "version-nested-menu", [], ["title", "Asset Types", "isActive", ["subexpr", "@mut", [["get", "isTypeActive", ["loc", [null, [9, 63], [9, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "items", ["subexpr", "@mut", [["get", "types", ["loc", [null, [10, 40], [10, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "isExpanded", ["subexpr", "@mut", [["get", "isExpanded", ["loc", [null, [10, 57], [10, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "topLink", "config.asset-types", "subLink", "config.asset-type"], ["loc", [null, [9, 12], [11, 93]]], 0, 0], ["block", "if", [["get", "type", ["loc", [null, [13, 20], [13, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [13, 14], [18, 21]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "sheer/components/asset-menu/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "mini-login-form", [], ["login", "login", "errorMessage", ["subexpr", "@mut", [["get", "loginErrorMessage", ["loc", [null, [25, 47], [25, 64]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [25, 2], [25, 66]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 26,
            "column": 7
          }
        },
        "moduleName": "sheer/components/asset-menu/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "session.isAuthenticated", ["loc", [null, [1, 6], [1, 29]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [26, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});