define('sheer/components/light-table/cells/field-selector-cell', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;

  var SCALAR_FORMATS = ['string', 'integer', 'boolean'];

  exports['default'] = Component.extend({
    NON_ATTRIBUTE_BOUND_PROPS: ['table', 'column', 'row', 'rawvalue'],
    classNames: 'table-cell-spaced',

    _flatFields: function _flatFields(field) {
      return field && {
        label: get(field, 'questionLabel') + ' - ' + get(field, 'label'),
        id: get(field, 'id'),
        value: get(field, 'values'),
        fieldType: 'projectData',
        field: field
      };
    },

    _flatRefFields: function _flatRefFields(field) {
      return field && {
        label: field.refDef.get('name') + ' - ' + field.path,
        id: field.refDef.get('name') + '.' + field.path,
        refDef: field.refDef,
        fieldType: 'assetRef',
        field: field
      };
    },

    _setValueResProj: function _setValueResProj(field) {
      var fieldId = get(field, 'id') || get(field, 'path');
      var answers = this.get('table.answers');
      var theAnswer = answers && answers.filter(function (x) {
        return x.field.id === fieldId;
      });

      var theVals = null;
      if (theAnswer && theAnswer.length > 0) {
        theVals = theAnswer[0].answerVal;
      }

      var hintObj = { value: theVals, id: fieldId, type: field.fieldType };
      this.sendAction('update', hintObj);
      return hintObj;
    },

    _setValueResRef: function _setValueResRef(field) {
      var fieldId = field.field && field.field.path || field.path;
      var dep = field.refDef.name;
      if (typeof dep !== 'string') {
        dep = field.refDef.get('name');
      }
      var path = dep + '.' + fieldId;
      var hintObj = { value: path, id: path, type: 'assetRef' };
      this.sendAction('update', hintObj);
      return hintObj;
    },

    _setValueRes: function _setValueRes(field) {
      switch (field.fieldType) {
        case 'assetRef':
          return this._setValueResRef(field);

        default:
          return this._setValueResProj(field);
      }
    },

    _fieldValFromMapping: function _fieldValFromMapping() {
      var mapping = this.get('row.mapping');
      var selField = this.get('row.selField');

      if (mapping && !selField) {
        var textFields = this.get('fieldOptionsNoGate');
        if (textFields) {
          var foundField = textFields.filter(function (x) {
            return x.id === mapping.dataRefId || x.id === mapping.refId;
          });
          selField = foundField && foundField.length > 0 ? foundField[0] : null;
          this.set('row.selField', selField && selField.field);
        }
      }

      return selField && selField.field;
    },

    selectedField: computed('row.selField', 'fieldOptionsNoGate.[]', 'row.mapping', function () {
      var field = this.get('row.selField') || this._fieldValFromMapping();
      var fType = field && field.type || '';

      if (field) {
        this._setValueRes(field);
      }

      if (SCALAR_FORMATS.contains(fType)) {
        return this._flatRefFields(field);
      } else {
        return this._flatFields(field);
      }
    }),

    valSelected: function valSelected(val) {
      this.set('row.selField', val.field);
      this.set('row.mapping', this._setValueRes(val));
    },

    focus: function focus() {
      this.set('isFocussed', true);
    },

    blur: function blur() {
      this.set('isFocussed', false);
    },

    fieldOptionsNoGate: computed('table.textFields.[]', 'table.refFields.[]', function () {
      var fields = this.get('table.textFields');
      if (fields) {
        fields = fields.map(this._flatFields);
      }
      var vals = this.get('table.refFields');
      if (vals) {
        vals.filter(function (f) {
          return SCALAR_FORMATS.contains(f.type);
        }).map(this._flatRefFields).forEach(function (x) {
          return fields.push(x);
        });
      }

      return fields;
    }),

    fieldOptions: computed('fieldOptionsNoGate.[]', 'isFocussed', function () {
      if (this.get('isFocussed')) {
        return this.get('fieldOptionsNoGate');
      } else {
        return [];
      }
    })

  });
});