define('sheer/components/template-property-editors/template-list/component', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({

    templates: computed('propertyValue', function () {
      return this.getWithDefault('propertyValue', []).map(function (x) {
        return { 'name': x };
      });
    }),

    templateNames: computed('templates.[]', function () {
      return this.get('templates').map(function (v) {
        return get(v, 'name');
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);

      var options = this.get('propertyType._property.optionsAre');
      if (options) {
        this.get('typeLookup').perform(options.type);
      } else {
        this.set('defOptions', false);
      }
    },

    typeLookup: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(type) {
      var tmps;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.get('lookupTemplatesForType')(type);

          case 2:
            tmps = context$1$0.sent;

            this.set('defOptions', tmps.map(function (x) {
              return {
                name: x.get('name')
              };
            }));

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    possibleValues: computed('tags', 'defOptions', 'templateNames.[]', function () {
      var _this = this;

      var tmpls = this.get('templateNames');

      var vals = this.get('defOptions');
      if (!vals) {
        var tags = this.getWithDefault('tags', []);
        var posVals = tags.filter(function (x) {
          var cat = get(x, 'category');
          return cat === _this.get('propertyName');
        });
        vals = posVals.length > 0 ? posVals[0].get('values') : [];
      }
      return vals.filter(function (x) {
        return !tmpls.contains(x.name);
      });
    }),

    _updateVal: function _updateVal(selectedTemplates) {
      this.get('dataChanged')({
        name: this.get('propertyPath'),
        type: 'templateList',
        data: selectedTemplates || this.get('templateNames')
      });
    },

    mutTemplates: function mutTemplates(newVals) {
      this.set('templates', newVals);
      this._updateVal();
    }

  });
});