define("sheer/templates/components/light-table/cells/asset-ref-selector-cell", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 6,
              "column": 0
            }
          },
          "moduleName": "sheer/templates/components/light-table/cells/asset-ref-selector-cell.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "ps.label", ["loc", [null, [5, 2], [5, 14]]], 0, 0, 0, 0]],
        locals: ["ps"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 9
          }
        },
        "moduleName": "sheer/templates/components/light-table/cells/asset-ref-selector-cell.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "power-select", [], ["class", "fw200", "selected", ["subexpr", "@mut", [["get", "selectedField", ["loc", [null, [1, 39], [1, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "@mut", [["get", "fieldOptions", ["loc", [null, [1, 61], [1, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", ["subexpr", "@mut", [["get", "id", ["loc", [null, [2, 32], [2, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "optionLabelPath", ["subexpr", "@mut", [["get", "label", ["loc", [null, [2, 51], [2, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "includeBlank", true, "searchField", "label", "onopen", ["subexpr", "action", [["get", "focus", ["loc", [null, [3, 31], [3, 36]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 23], [3, 37]]], 0, 0], "onclose", ["subexpr", "action", [["get", "blur", ["loc", [null, [3, 54], [3, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 46], [3, 59]]], 0, 0], "onchange", ["subexpr", "action", [["get", "valSelected", ["loc", [null, [3, 77], [3, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 69], [3, 89]]], 0, 0]], 0, null, ["loc", [null, [1, 0], [6, 17]]]], ["content", "yield", ["loc", [null, [8, 0], [8, 9]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});