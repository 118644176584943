define('sheer/models/asset-ref-def', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({

    assetType: belongsTo('assetType'),
    name: attr('string'),
    minVer: attr('number'),
    maxVer: attr('number'),
    refAssetType: belongsTo('assetType')

  });
});