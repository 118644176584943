define('sheer/components/light-table/cells/value-data-cell', ['exports', 'ember'], function (exports, _ember) {
  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    NON_ATTRIBUTE_BOUND_PROPS: ['table', 'column', 'row', 'rawvalue'],
    classNames: 'table-cell-spaced',

    _flatFields: function _flatFields(answer) {
      return {
        label: answer.get('questionLabel') + ' - ' + answer.get('label'),
        id: answer.get('id'),
        value: answer.get('values')
      };
    },

    // Create a fake table & row objects as a field in case we are using this not inside a table
    table: computed('fields.[]', 'refTypeProps.[]', 'refDefs.[]', function () {
      return {
        textFields: this.get('fields'),
        refFields: this.get('refTypeProps'),
        refDefs: this.get('refDefs')
      };
    }),

    _fieldByVariableMapping: function _fieldByVariableMapping(mapping) {
      var fields = this.get('fields');
      if (fields && mapping) {
        var fFields = [];
        var type = mapping.dataRefSource;
        if (type === 'projectData') {
          fFields = fields.filter(function (x) {
            return x.get('id') === mapping.dataRefId;
          });
        } else if (type === 'assetObjRef') {
          fFields = this.getWithDefault('refDefs', []).filter(function (x) {
            return get(x, 'name') === mapping.dataRefId;
          });

          if (fFields.length > 0) {
            return fFields[0];
          }
        } else if (type === 'assetRef') {
          fFields = this.getWithDefault('refTypeProps', []).filter(function (x) {
            var _mapping$dataRefId$split = mapping.dataRefId.split('.');

            var _mapping$dataRefId$split2 = _toArray(_mapping$dataRefId$split);

            var obj = _mapping$dataRefId$split2[0];

            var parts = _mapping$dataRefId$split2.slice(1);

            return parts.join('.') === x.path && obj === x.refDef.get('name');
          });
        }

        if (fFields.length > 0) {
          return fFields[0];
        }
      }

      return null;
    },

    row: computed('valueType', 'type', 'variable', 'variable.mapping', function () {
      var mapping = this.get('variable.mapping');
      var vType = this.get('valueType');
      return {
        variable: this.get('variable'),
        mapping: mapping,
        valueType: vType,
        selField: this._fieldByVariableMapping(mapping),
        type: this.get('variable.type')
      };
    }),

    projectOptions: computed('table.project', 'fields', 'valueType', 'table.answers.length', 'row.valueType', function () {
      var valType = this.get('row.valueType') || this.get('valueType');
      var fields = this.get('table.fields') || this.get('fields');

      switch (valType.id) {
        case 'projectData':
          return fields.map(this._flatFields);
        default:
          return [];
      }
    }),

    valueType: computed('row.valueType', function () {
      return this.get('row.valueType') || this.get('valueType');
    }),

    isSelect: computed('row.valueType', 'valueType', function () {
      var valType = this.get('row.valueType') || this.get('valueType');

      switch (valType) {
        case 'static':
          return false;

        case 'projectData':
          return true;

        default:
          return true;
      }
    }),

    updateStaticValue: function updateStaticValue(text) {

      var valUpFunc = this.get('table.varValChanged') || this.get('varValChanged');
      if (valUpFunc) {
        valUpFunc(this.get('row'), { 'type': 'static', 'value': text });
      }
    },

    updateFieldSelector: function updateFieldSelector(arg) {
      var varChanged = this.get('varChanged');
      if (varChanged) {
        varChanged(arg.type, arg.id, this.get('row'));
      }
      var valUpFunc = this.get('table.varValChanged') || this.get('varValChanged');
      if (valUpFunc) {
        valUpFunc(this.get('row'), {
          'type': 'field', 'value': arg.value, 'id': arg.id
        });
      }
    }

  });
});