define('sheer/models/asset_template', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var belongsTo = _emberData['default'].belongsTo;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend({
    assetType: belongsTo('asset_type'),
    name: attr('string'),
    version: attr('number'),
    status: attr(),
    lastUpdated: attr(),
    documentTemplate: attr(),
    variableDefs: hasMany('variable_defs'),
    constraints: hasMany('constraints'),
    assetTemplateBlobs: hasMany('asset_template_blobs')
  });
});