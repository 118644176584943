define('sheer/controllers/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  var service = _ember['default'].inject.service;
  var Controller = _ember['default'].Controller;
  exports['default'] = Controller.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    breadCrumb: 'Tools',
    session: service(),
    actions: {
      logout: function logout() {
        this.get('session').invalidate();
      }
    }
  });
});