define('sheer/services/doc-defaults', ['exports', 'ember'], function (exports, _ember) {
  var Service = _ember['default'].Service;
  exports['default'] = Service.extend({
    updateDocValsWithSchemaDefaults: function updateDocValsWithSchemaDefaults(props, docObj) {
      docObj = docObj || {};

      for (var k in props) {
        if (props.hasOwnProperty(k)) {
          var v = props[k];

          this._setPropDefaults(docObj, k, v);
        }
      }

      return docObj;
    },

    _setPropDefaults: function _setPropDefaults(docSection, name, val) {
      if (val.title) {
        var useSection = docSection[name];
        if (!useSection) {
          useSection = val.items ? [] : {};
          docSection[name] = useSection;
        }
        for (var k in val.properties) {
          if (val.properties.hasOwnProperty(k)) {
            var v = val.properties[k];
            this._setPropDefaults(useSection, k, v);
          }
        }
      } else {
        var defVal = val['default'];
        if (typeof defVal !== 'undefined') {
          if (!docSection.hasOwnProperty(name)) {
            docSection[name] = defVal;
          }
        }
      }
    }
  });
});