define('sheer/serializers/json-subset-serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  var JSONAPISerializer = _emberData['default'].JSONAPISerializer;
  exports['default'] = JSONAPISerializer.extend({

    init: function init() {
      var allowedTypes = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

      this._allowedType = function (x) {
        return allowedTypes.contains(x);
      };
    },

    normalizeFindAllResponse: function normalizeFindAllResponse(store, type, payload) {
      return this._super(store, type, {
        data: payload.data,
        included: payload.included && payload.included.filter(this._allowedType) || []
      });
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, type, payload) {
      return this._super(store, type, {
        data: payload.data,
        included: payload.included && payload.included.filter(this._allowedType) || []
      });
    }
  });
});