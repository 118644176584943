define('sheer/controllers/asset-library/types/templates', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'sheer/config/environment', 'ember-concurrency'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin, _sheerConfigEnvironment, _emberConcurrency) {
  var Controller = _ember['default'].Controller;
  var $ = _ember['default'].$;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var inject = _ember['default'].inject;
  exports['default'] = Controller.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    renderSvc: inject.service('preview-render'),

    breadCrumb: computed.alias('type.name'),
    createShowing: false,
    previewShowing: false,

    editType: computed('typeName', function () {

      var typeName = this.get('typeName');
      var type = { 'version': -1 };

      this.get('types').forEach(function (x) {
        if (x.get('name') === typeName && x.get('version') > get(type, 'version')) {
          type = x;
        }
      });

      return type;
    }),
    createPreviewAsset: function createPreviewAsset(data) {
      return $.post(_sheerConfigEnvironment['default'].host + '/composed/templatePreview/' + data.get('id'));
    },
    _updatePreviewAsset: function _updatePreviewAsset(previewAssets, template, k, v) {
      var tmplId = parseInt(template.get('id'));
      var prevA = previewAssets.get(tmplId);
      set(prevA, k, v);
      previewAssets.set(tmplId, prevA);
      template.set(k, v);
      return prevA;
    },
    createRenders: function createRenders(previewAssets, template, data) {
      var _this = this;

      this.get('renderSvc').startRenders(data.asset.id, function (x) {
        return _this._updatePreviewAsset(previewAssets, template, 'thumbnailUrl', x);
      }, function (x) {
        return _this._updatePreviewAsset(previewAssets, template, 'previewUrl', x);
      });
    },
    createTemplateAndPreview: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(data) {
      var tmpl, newone, thetype, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, blobid, theblob, saveRes, newAsset, newAssetObj, prevAssets;

      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        var _this2 = this;

        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:

            this.set('templateSaved', false);
            this.set('previewSaved', false);
            this.set('saveStatus', null);

            tmpl = this.get('template');
            newone = null;

            if (tmpl && data.version === tmpl.get('version')) {
              newone = tmpl;
            } else {
              newone = this.get('store').createRecord('asset-template', {
                name: data.name || this.get('name'),
                version: '1',
                documentTemplate: data.documentTemplate || this.get('document_template')

              });
            }

            thetype = this.get('store').peekRecord('asset-type', data.assetTypeId);

            newone.set('assetType', thetype);

            _iteratorNormalCompletion = true;
            _didIteratorError = false;
            _iteratorError = undefined;
            context$1$0.prev = 11;
            for (_iterator = data.blobs[Symbol.iterator](); !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              blobid = _step.value;
              theblob = this.get('store').peekRecord('asset-template-blob', blobid);

              if (!theblob) {
                theblob = this.get('store').createRecord('asset-template-blob', {
                  id: blobid
                });
              }

              newone.get('assetTemplateBlobs').pushObject(theblob);
            }

            context$1$0.next = 19;
            break;

          case 15:
            context$1$0.prev = 15;
            context$1$0.t0 = context$1$0['catch'](11);
            _didIteratorError = true;
            _iteratorError = context$1$0.t0;

          case 19:
            context$1$0.prev = 19;
            context$1$0.prev = 20;

            if (!_iteratorNormalCompletion && _iterator['return']) {
              _iterator['return']();
            }

          case 22:
            context$1$0.prev = 22;

            if (!_didIteratorError) {
              context$1$0.next = 25;
              break;
            }

            throw _iteratorError;

          case 25:
            return context$1$0.finish(22);

          case 26:
            return context$1$0.finish(19);

          case 27:
            context$1$0.prev = 27;
            context$1$0.next = 30;
            return newone.save();

          case 30:
            saveRes = context$1$0.sent;

            this.set('templateSaved', true);

            this.set('templates', this.get('store').peekAll('asset-template').filter(function (x) {
              return x.get('assetType.name') === _this2.get('typeName');
            }));

            context$1$0.prev = 33;
            context$1$0.next = 36;
            return this.createPreviewAsset(saveRes);

          case 36:
            newAsset = context$1$0.sent;
            newAssetObj = typeof newAsset === 'string' ? JSON.parse(newAsset) : newAsset;
            prevAssets = this.get('previewAssets');

            prevAssets.set(parseInt(newAssetObj.asset.previews_template), newAssetObj.asset);

            this.set('previewAssets', prevAssets);
            this.set('previewAssetKeys', prevAssets.keys());

            this.createRenders(prevAssets, newone, newAssetObj);

            this.set('previewSaved', true);
            this.set('createShowing', false);
            this.set('previewShowing', false);
            context$1$0.next = 51;
            break;

          case 48:
            context$1$0.prev = 48;
            context$1$0.t1 = context$1$0['catch'](33);

            this.set('saveStatus', 'Error saving preview: ' + JSON.stringify(context$1$0.t1.errors || context$1$0.t1));

          case 51:
            context$1$0.next = 56;
            break;

          case 53:
            context$1$0.prev = 53;
            context$1$0.t2 = context$1$0['catch'](27);

            this.set('saveStatus', 'Error saving template: ' + JSON.stringify(context$1$0.t2.errors || context$1$0.t2));

          case 56:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this, [[11, 15, 19, 27], [20,, 22, 26], [27, 53], [33, 48]]);
    })).restartable(),

    templateClick: function templateClick(url, template) {
      var _this3 = this;

      this.set('previewShowing', true);
      this.set('saveStatus', '');
      this.set('previewSaved', false);
      this.set('templatedSaved', false);
      this.set('previewUrl', url);

      this.get('store').findRecord('asset-template', template.get('id'), { reload: true }).then(function (data) {
        _this3.set('template', data);
      });
    },
    closePreview: function closePreview() {
      this.set('previewShowing', false);
    },
    lookupTemplatesForType: function lookupTemplatesForType(type) {
      return this.get('store').query('asset-template', { 'filter': { 'assetTypeName': type } });
    },
    closeTemplateEditor: function closeTemplateEditor() {
      this.set('createShowing', false);
      this.set('previewShowing', false);
    },
    actions: {
      addTemplate: function addTemplate() {
        this.set('template', null);
        this.set('saveStatus', '');
        this.set('previewSaved', false);
        this.set('templatedSaved', false);

        this.toggleProperty('createShowing');
      },
      create: function create(data) {
        this.get('createTemplateAndPreview').perform(data);
      }
    }
  });
});