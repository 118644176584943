define("sheer/templates/components/template-property-editor", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "sheer/templates/components/template-property-editor.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-12 col-sm-6 col-xs-12");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "class", "control-label col-md-2 col-sm-1 col-xs-1");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-10");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "list-group");
        var el5 = dom.createTextNode("\n\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "list-group-item col-md-12");
        var el6 = dom.createTextNode("\n      ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1, 1]), 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        return morphs;
      },
      statements: [["content", "propertyName", ["loc", [null, [4, 60], [4, 76]]], 0, 0, 0, 0], ["inline", "component", [["subexpr", "form-elem-for-schema-prop", [["get", "propertyType", ["loc", [null, [9, 45], [9, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 18], [9, 58]]], 0, 0]], ["class", ["subexpr", "@mut", [["get", "propClass", ["loc", [null, [10, 44], [10, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "templateId", ["subexpr", "@mut", [["get", "templateId", ["loc", [null, [10, 65], [10, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "propertyName", ["subexpr", "@mut", [["get", "propertyName", ["loc", [null, [10, 89], [10, 101]]], 0, 0, 0, 0]], [], [], 0, 0], "propertyPath", ["subexpr", "@mut", [["get", "propertyPath", ["loc", [null, [11, 53], [11, 65]]], 0, 0, 0, 0]], [], [], 0, 0], "propertyType", ["subexpr", "@mut", [["get", "propertyType", ["loc", [null, [11, 79], [11, 91]]], 0, 0, 0, 0]], [], [], 0, 0], "propertyValue", ["subexpr", "@mut", [["get", "propertyValue", ["loc", [null, [11, 106], [11, 119]]], 0, 0, 0, 0]], [], [], 0, 0], "tags", ["subexpr", "@mut", [["get", "tags", ["loc", [null, [12, 45], [12, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "dataChanged", ["subexpr", "@mut", [["get", "dataChanged", ["loc", [null, [12, 62], [12, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "lookupTemplatesForType", ["subexpr", "@mut", [["get", "lookupTemplatesForType", ["loc", [null, [12, 97], [12, 119]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [9, 6], [12, 121]]], 0, 0], ["content", "yield", ["loc", [null, [17, 0], [17, 9]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});