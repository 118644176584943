define('sheer/models/asset', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var belongsTo = _emberData['default'].belongsTo;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend({
    assetType: belongsTo('asset_type'),
    assetTemplate: belongsTo('asset_template'),
    name: attr('string'),
    projectId: attr('number'),
    previewsTemplate: attr('number'),
    version: attr('number'),
    document: attr(),

    assetRefs: hasMany('asset_ref'),
    variables: hasMany('variables'),
    assetBlobs: hasMany('asset_blobs')
  });
});