define('sheer/routes/config/asset-type/index', ['exports', 'ember'], function (exports, _ember) {
  var RSVP = _ember['default'].RSVP;
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend({
    controllerName: 'config.asset-type',
    renderTemplate: function renderTemplate(controller) {

      this.render();

      controller.set('isTypeActive', true);

      this.render('asset-menu', {
        into: 'application',
        outlet: 'assetMenu'
      });
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
        this.controller.set('test', 'thetest');
      }
    },
    setupController: function setupController(controller, models) {
      this._super(controller, models);

      var type = models.type;

      var dschema = type.get('documentSchema');
      type.set('documentSchemaObj', JSON.parse(dschema));
      type.set('isSchemaParsed', true);
      controller.set('types', models.types);
      controller.set('type', type);
    },
    model: function model(params) {
      return RSVP.hash({
        types: this.get('store').findAll('asset-type'),
        type: this.get('store').findRecord('asset-type', params.assettypeId, { reload: true })
      });
    }
  });
});