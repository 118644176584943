define("sheer/components/template-property-editors/map/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 0
          }
        },
        "moduleName": "sheer/components/template-property-editors/map/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-md-12");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-6");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-12");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-12");
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "form-label");
        var el5 = dom.createTextNode("Used:  ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-6");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [3, 2]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        return morphs;
      },
      statements: [["inline", "g-autocomplete", [], ["value", ["subexpr", "@mut", [["get", "address", ["loc", [null, [3, 50], [3, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-control", "local-class", "search", "on-select", ["subexpr", "action", [["get", "didUpdatePlace", ["loc", [null, [6, 62], [6, 76]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 54], [6, 77]]], 0, 0], "on-select-error", ["subexpr", "action", [["get", "invalidUserSelection", ["loc", [null, [7, 68], [7, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 60], [7, 89]]], 0, 0]], ["loc", [null, [3, 27], [7, 91]]], 0, 0], ["inline", "one-way-checkbox", [], ["checked", ["subexpr", "@mut", [["get", "propertyValue.used", ["loc", [null, [9, 48], [9, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["get", "updateUsed", ["loc", [null, [9, 82], [9, 92]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 74], [9, 93]]], 0, 0]], ["loc", [null, [9, 21], [9, 95]]], 0, 0], ["inline", "g-maps", [], ["name", ["subexpr", "@mut", [["get", "propertyPath", ["loc", [null, [12, 18], [12, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "lat", ["subexpr", "@mut", [["get", "propertyValue.latitude", ["loc", [null, [13, 17], [13, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "lng", ["subexpr", "@mut", [["get", "propertyValue.longitude", ["loc", [null, [14, 17], [14, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "zoom", ["subexpr", "@mut", [["get", "zoom", ["loc", [null, [15, 18], [15, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "mapType", "roadmap", "showMapTypeControl", false, "draggable", false, "showZoomControl", false, "showScaleControl", false, "disableDefaultUI", true, "clickableIcons", false, "disableDoubleClickZoom", true, "markers", ["subexpr", "@mut", [["get", "markers", ["loc", [null, [24, 21], [24, 28]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [12, 4], [24, 30]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});