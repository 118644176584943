define('sheer/controllers/preview-asset', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({
    docJSON: computed('model.doc', function () {
      return JSON.stringify(this.get('model.doc'));
    }),
    fileURL: computed('model.doc', function () {
      return this.get('model.doc.SvgFile.url').replace('http://', '//');
    })
  });
});