define('sheer/routes/config/asset-types', ['exports', 'ember'], function (exports, _ember) {
  var RSVP = _ember['default'].RSVP;
  var Route = _ember['default'].Route;
  var service = _ember['default'].inject.service;
  exports['default'] = Route.extend({
    session: service('session'),
    controllerName: 'config.asset-type',
    renderTemplate: function renderTemplate(controller) {
      this.render();

      controller.set('isTypeActive', true);

      this.render('asset-menu', {
        into: 'application',
        outlet: 'assetMenu'
      });
    },
    setupController: function setupController(controller, models) {
      this._super(controller, models);

      controller.set('types', models.types);
      controller.set('type', models.type);
    },
    model: function model() {
      return RSVP.hash({
        types: this.get('store').findAll('asset-type')
      });
    }
  });
});