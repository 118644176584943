define('sheer/components/template-editor/component', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  var inject = _ember['default'].inject;
  var on = _ember['default'].on;
  var observer = _ember['default'].observer;
  exports['default'] = Component.extend({

    version: 0,
    statusOpts: ['Draft', 'Live', 'Archived'],
    versionOpts: computed('model', function () {

      var ver = 0;
      var model = this.get('model');
      var template = model && model.template;
      if (template) {
        ver = template.get('version');
      }

      return Array.from(new Array(10), function (x, i) {
        return i + ver;
      });
    }),

    versionText: computed('type', 'oldType', function () {
      var typV = this.get('type.version');
      var oldTypV = this.get('oldType.version');

      if (oldTypV !== typV) {
        return '(v' + oldTypV + ' -> ' + typV + ')';
      } else {
        return null;
      }
    }),

    typeOpts: computed('types', 'showAllTypes', function () {
      var _this = this;

      if (this.magicMode) {
        return [];
      }

      var types = this.get('types');
      var showAll = this.get('showAllTypes');

      var res = [];
      types.forEach(function (t) {
        var name = t.get('name');
        var ver = t.get('version');

        if (name === _this.get('type.name')) {
          t.set('label', name + ' (v' + ver + ')');

          if (showAll) {
            res.pushObject(t);
          } else if (_this.get('version') <= ver) {
            res.pushObject(t);
          }
        }
      });

      return res;
    }),

    type: computed('template.assetType', function () {
      return this.get('template.assetType');
    }),

    templateId: computed('template.id', function () {
      if (this.get('template')) {
        return this.get('template.id');
      }
    }),

    blobNamesJson: computed('blobNames.length', function () {
      return JSON.stringify(this.get('blobNames'));
    }),

    variablesWithMapping: computed('variables', 'variables.[]', 'newVars', 'newVars.[]', 'documentTemplateObj', 'documentTemplateObj.DataMapping', 'documentTemplateObj.DataMapping.[]', function () {
      var dobj = this.getWithDefault('documentTemplateObj', {});
      var dm = dobj.DataMapping;

      if (!dm) {
        dm = [];
      }

      var oldVars = this.getWithDefault('variables', []);
      var variables = [].concat(_toConsumableArray(oldVars.toArray()), _toConsumableArray(this.get('newVars')));
      var mappings = {};

      if (dm && dm.hasOwnProperty('length') && variables) {
        dm.forEach(function (x) {
          mappings[x.variableName] = x;
        });

        return variables.map(function (x) {
          if (mappings.hasOwnProperty(get(x, 'name'))) {
            set(x, 'mapping', mappings[get(x, 'name')]);
            return x;
          } else {
            return x;
          }
        });
      }
    }),

    schemaService: inject.service('schema-resolver'),
    docDefService: inject.service('doc-defaults'),
    refTypeService: inject.service('ref-types'),

    _loadRefData: function _loadRefData(typ) {
      var _this2 = this;

      if (typ) {
        this.get('schemaService').loadSchemaForType(typ).then(function () {
          var rd = typ.get('assetRefDefs');
          _this2.set('refDefs', rd);
          if (rd) {
            _this2.get('refTypeService').loadRefsSchemas(_this2.get('types'), typ).then(function (x) {
              var res = [];
              x.forEach(function (a) {
                res = res.concat(a);
              });

              _this2.set('refTypeProps', res);
            });
          }
        });
      }
    },

    schema: computed('type', function () {
      var _this3 = this;

      var typ = this.get('type');
      this.get('schemaService').loadSchemaForType(typ).then(function () {
        _this3._loadRefData(typ);
      });
    }),

    schemaProps: computed('schema', function () {
      var schema = this.get('schema');
      var MIN_SORT_LENGTH = 20;

      if (!schema) {
        return undefined;
      }

      if (Object.keys(schema.properties).length > MIN_SORT_LENGTH) {
        return this.sortObject(schema.properties);
      } else {
        return schema.properties;
      }
    }),

    documentTemplateObj: computed('documentTemplate', function () {
      var dtmpl = this.get('documentTemplate');
      return dtmpl && JSON.parse(dtmpl);
    }),

    willDestroyElement: function willDestroyElement() {

      this.get('schemaService').clearHooks();

      this._super.apply(this, arguments);
    },

    init: function init() {
      var _this4 = this;

      this._super.apply(this, arguments);

      if (!this.get('newVars')) {
        this.set('newVars', []);
      }

      var appl = this.get('applicators');
      if (appl && appl.content && appl.content.length > 0) {
        this.set('newvarappl', appl.content[0].id);
      }

      var doc = this.get('documentTemplate');
      var docObj = this.get('documentTemplateObj');
      if (!doc) {
        doc = '{}';
        docObj = {};
        this.set('documentTemplate', '{}');
      } else {
        docObj = JSON.parse(doc);
      }

      var schmS = this.get('schemaService');
      var type = this.get('type');
      schmS.onUpdate(type, function (typ) {
        var thisType = _this4.get('type');

        if (typ.get('id') === thisType.get('id')) {
          _this4.set('schema', schmS.schema(_this4.get('type')));

          var _docObj = _this4.get('docDefService').updateDocValsWithSchemaDefaults(_this4.get('schemaProps'), _this4.get('documentTemplateObj'));
          _this4.set('documentTemplate', JSON.stringify(_docObj));
        }
      });
    },

    typeChanged: on('init', observer('type', function () {
      var _this5 = this;

      var type = this.get('type');
      var schmS = this.get('schemaService');
      schmS.loadSchemaForType(type).then(function () {
        _this5._loadRefData(type);

        _this5.set('schema', schmS.schema(type));
      });
    })),

    varChanged: function varChanged(type, id, row) {
      if (row && row.selField) {
        var oldMapping = get(row, 'variable.mapping');
        var newMapping = {
          dataRefId: id,
          dataRefSource: type || 'projectData',
          variableName: get(row.variable, 'name')
        };

        if (JSON.stringify(oldMapping) !== JSON.stringify(newMapping)) {
          set(row.variable, 'mapping', newMapping);
        }

        // Add it to the data mapping
        var docObj = this.get('documentTemplateObj');
        var dm = docObj.DataMapping || [];
        var wasFound = false;
        var changed = false;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = dm[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var adm = _step.value;

            if (adm.variableName === newMapping.variableName) {
              wasFound = true;
              if (adm.dataRefId !== newMapping.dataRefId || adm.dataRefSource !== newMapping.dataRefSource) {
                changed = true;
                set(adm, 'dataRefId', newMapping.dataRefId);
                set(adm, 'dataRefSource', newMapping.dataRefSource);
              }
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator['return']) {
              _iterator['return']();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        if (!wasFound) {
          dm.push(newMapping);
          changed = true;
        }

        if (changed) {
          set(docObj, 'DataMapping', dm);
          this.set('documentTemplateObj', docObj);
          this.set('documentTemplate', JSON.stringify(docObj));
        }
      }
    },

    keyUp: function keyUp(e) {
      if (e.keyCode === 27) {
        // escape key
        this.closeEditor();
      }
    },

    closeEditor: function closeEditor() {
      var cte = this.get('closeTemplateEditor');
      if (cte) {
        cte();
      }
    },

    actions: {
      deleteTemplate: function deleteTemplate() {
        var id = this.get('id') || this.get('template.id');
        this.sendAction('delete', id);
      },

      toggleAllTypes: function toggleAllTypes() {
        this.toggleProperty('showAllTypes');
      },
      propertyDataChanged: function propertyDataChanged(ctxt) {

        var pathStack = ctxt.name.split('.');

        var doc = this.getWithDefault('documentTemplateObj', {});
        var blobs = this.getWithDefault('blobs', []);
        var blobNames = this.getWithDefault('blobNames', []);
        var name = pathStack.pop();
        var loc = doc;

        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = pathStack[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var pathPart = _step2.value;

            var newLoc = loc[pathPart];
            if (!newLoc) {
              newLoc = {};
              loc[pathPart] = newLoc;
            }
            loc = newLoc;
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2['return']) {
              _iterator2['return']();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        loc[name] = ctxt.data;

        if (ctxt.blobId) {
          if (!blobs.contains(ctxt.blobId)) {
            blobs.pushObject(ctxt.blobId);
          }

          blobNames.pushObject({ name: name, 'blobId': ctxt.blobId });
        }

        if (ctxt.type === 'namedFiles') {
          ctxt.data.forEach(function (namedFile) {
            if (!blobs.contains(namedFile.blobId)) {
              blobs.pushObject(namedFile.blobId);
              blobNames.pushObject({ 'name': namedFile.blobName, 'blobId': namedFile.blobId });
            }
          });
        }

        this.set('documentTemplate', JSON.stringify(doc));
        this.set('blobs', blobs);
        this.set('blobNames', blobNames);
      },

      create: function create() {
        var tmpl = this.getWithDefault('documentTemplate', '{}');
        var blobs = this.getWithDefault('blobs', []);
        var name = this.get('name');
        var status = this.getWithDefault('status', 'Live');
        var typeId = this.get('type').get('id');
        var ver = this.getWithDefault('version', 0);
        var vars = [].concat(_toConsumableArray(this.getWithDefault('variables', [])), _toConsumableArray(this.getWithDefault('newVars', [])));

        this.sendAction('create', {
          name: name,
          version: ver,
          status: status,
          documentTemplate: tmpl,
          blobs: blobs,
          variableDefs: vars,
          assetTypeId: typeId
        });

        this.set('newVars', []);
      },

      popExtractDialog: function popExtractDialog(id) {
        this.set('extBlobId', id);
        this.set('isShowingExtractDialog', true);
      },

      closeExtractDialog: function closeExtractDialog() {
        this.set('isShowingExtractDialog', false);
      },

      applyExtractedVariables: function applyExtractedVariables(data) {
        var _this6 = this;

        var hints = [];
        var oldVars = this.get('variables');
        data.toArray().forEach(function (e) {
          var included = typeof data.include === 'undefined' ? true : data.include;
          if (included) {
            (function () {
              var newvar = {
                name: e.variableHint,
                applicator: e.applicatorName,
                applicatorData: e.applicatorData,
                type: e.type,
                hint: e.hint,
                'default': e['default']
              };

              var varDataRef = e.selField;

              if (varDataRef) {
                hints.push({ 'variableName': e.variableHint,
                  'dataRefSource': e.mapping.type,
                  'dataRefId': e.mapping.id || e.mapping.refId });
              }

              var wasUpdate = false;
              oldVars.forEach(function (x) {
                if (x.get('name') === newvar.name) {
                  x.setProperties(newvar);
                  wasUpdate = true;
                }
              });

              if (!wasUpdate) {
                _this6.get('newVars').pushObject(newvar);
              }
            })();
          }
        });
        this.set('variables', oldVars);

        // Add variable hints if desired
        // TODO: check if this is in the schema
        var docTemplate = this.get('documentTemplateObj');
        var newHints = docTemplate.DataMapping && docTemplate.DataMapping.length ? docTemplate.DataMapping : [];

        var hintNames = hints.map(function (x) {
          return x.variableName;
        });
        set(docTemplate, 'DataMapping', newHints.filter(function (x) {
          return !hintNames.contains(x.variableName);
        }).concat(hints));
        this.set('documentTemplate', JSON.stringify(docTemplate));

        this.set('isShowingExtractDialog', false);
      },

      addVariable: function addVariable() {
        var applid = this.get('newvarappl');
        var applname = this.get('applicators').find(function (x) {
          return x.id === applid;
        }).get('name');

        var newvar = {
          name: this.get('newvarname'),
          applicator: applname,
          applicatorData: this.get('newvarappldata'),
          hint: this.get('newhint'),
          'default': this.get('defaultValue')
        };

        this.get('newVars').pushObject(newvar);
      },

      deleteVariable: function deleteVariable(varId) {
        var vars = this.get('variables');
        this.set('variables', vars.filter(function (x) {
          return x.id !== varId;
        }));
      }

    },
    sortObject: function sortObject(o) {
      return Object.keys(o).sort().reduce(function (r, k) {
        return r[k] = o[k], r;
      }, {});
    }
  });
});