define("sheer/templates/config/asset-type/asset-templates", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "sheer/templates/config/asset-type/asset-templates.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "template-editor", [], ["title", "Asset Template (Create)", "isEdit", false, "types", ["subexpr", "@mut", [["get", "types", ["loc", [null, [2, 37], [2, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "type", ["subexpr", "@mut", [["get", "type", ["loc", [null, [2, 48], [2, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "applicators", ["subexpr", "@mut", [["get", "applicators", ["loc", [null, [3, 30], [3, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "fields", ["subexpr", "@mut", [["get", "fields", ["loc", [null, [3, 49], [3, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "tags", ["subexpr", "@mut", [["get", "tags", ["loc", [null, [3, 61], [3, 65]]], 0, 0, 0, 0]], [], [], 0, 0], "lookupTemplatesForType", ["subexpr", "action", [["get", "lookupTemplatesForType", ["loc", [null, [4, 49], [4, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [4, 41], [4, 72]]], 0, 0], "templateSaved", ["subexpr", "@mut", [["get", "templateSaved", ["loc", [null, [5, 32], [5, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "saveStatus", ["subexpr", "@mut", [["get", "saveStatus", ["loc", [null, [6, 29], [6, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "saveTask", ["subexpr", "@mut", [["get", "createTemplate", ["loc", [null, [6, 49], [6, 63]]], 0, 0, 0, 0]], [], [], 0, 0], "typeSelected", ["subexpr", "action", [["get", "typeSelected", ["loc", [null, [7, 39], [7, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 31], [7, 52]]], 0, 0], "create", "create"], ["loc", [null, [1, 0], [7, 70]]], 0, 0], ["content", "outlet", ["loc", [null, [9, 0], [9, 10]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});