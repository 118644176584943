define('sheer/services/schema-resolver', ['exports', 'ember', 'ember-concurrency', 'ember-json-schema-document/models/schema'], function (exports, _ember, _emberConcurrency, _emberJsonSchemaDocumentModelsSchema) {
  var Service = _ember['default'].Service;
  var assert = _ember['default'].assert;
  exports['default'] = Service.extend({

    loadSchemaForType: function loadSchemaForType(typ) {
      return this.get('schemaAugTask').perform(typ);
    },

    init: function init() {
      this._super.apply(this, arguments);

      this.set('schemas', {});
      this.set('updateFncs', {});
    },

    schemaAugTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(type) {
      var docSchema, rawSchema, schema, schemas, upds;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            docSchema = type.get('documentSchema');

            assert('Type sent to editor should have schema set', docSchema != null);
            context$1$0.next = 4;
            return $RefParser.dereference(JSON.parse(docSchema));

          case 4:
            rawSchema = context$1$0.sent;
            schema = new _emberJsonSchemaDocumentModelsSchema['default'](rawSchema);
            schemas = this.get('schemas');

            schemas[type.get('id')] = schema.buildDocument();
            this.set('schemas', schemas);

            upds = this.get('updateFncs');

            if (upds && upds[type.get('id')]) {
              upds[type.get('id')].forEach(function (x) {
                x(type);
              });
            }

          case 11:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    onUpdate: function onUpdate(typ, fnc) {
      var upFnc = this.get('updateFncs');
      var curFncs = upFnc[typ.get('id')];
      if (!curFncs) {
        curFncs = [];
      }
      curFncs.push(fnc);
      upFnc[typ.get('id')] = curFncs;
      this.set('updateFncs', upFnc);
    },

    schema: function schema(typ) {
      return this.get('schemas')[typ.get('id')];
    },

    clearHooks: function clearHooks() {
      this.set('updateFncs', {});
    }
  });
});