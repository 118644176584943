define('sheer/components/template-property-editors/boost-exclude-list/component', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({

    boosts: computed('propertyValue', function () {
      return this.getWithDefault('propertyValue.boosts', []).map(function (x) {
        return { 'name': x };
      });
    }),

    boostNames: computed('boosts.[]', function () {
      return this.get('boosts').map(function (v) {
        return get(v, 'name');
      });
    }),

    excludes: computed('propertyValue', function () {
      return this.getWithDefault('propertyValue.excludes', []).map(function (x) {
        return { 'name': x };
      });
    }),

    excludeNames: computed('excludes.[]', function () {
      return this.get('excludes').map(function (v) {
        return get(v, 'name');
      });
    }),

    includes: computed('propertyValue', function () {
      return this.getWithDefault('propertyValue.includes', []).map(function (x) {
        return { 'name': x };
      });
    }),

    includeNames: computed('includes.[]', function () {
      return this.get('includes').map(function (v) {
        return get(v, 'name');
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);

      var options = this.get('propertyType._property.optionsAre');
      if (options) {
        this.get('typeLookup').perform(options.type);
      } else {
        this.set('defOptions', false);
      }
    },

    typeLookup: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(type) {
      var tmps;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.get('lookupTemplatesForType')(type);

          case 2:
            tmps = context$1$0.sent;

            this.set('defOptions', tmps.map(function (x) {
              return {
                name: x.get('name')
              };
            }));

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    possibleValues: computed('tags', 'defOptions', 'boostNames.[]', 'excludeNames.[]', 'includeNames.[]', function () {
      var _this = this;

      var bst = this.get('boostNames');
      var exc = this.get('excludeNames');
      var inc = this.get('includeNames');
      var dopts = this.get('defOptions');

      var vals = dopts;
      if (!vals) {
        var tags = this.getWithDefault('tags', []);
        var posVals = tags.filter(function (x) {
          var cat = get(x, 'category');
          return cat === _this.get('propertyName');
        });
        vals = posVals.length > 0 ? posVals[0].get('values') : [];
      }
      return vals.filter(function (x) {
        return !bst.contains(x.name) && !exc.contains(x.name) && !inc.contains(x.name);
      });
    }),

    _updateVal: function _updateVal(boosted, excluded, included) {
      this.get('dataChanged')({
        name: this.get('propertyPath'),
        type: 'boostExclude',
        data: {
          'boosts': boosted || this.get('boostNames'),
          'excludes': excluded || this.get('excludeNames'),
          'includes': included || this.get('includeNames')
        }
      });
    },

    mutExcludes: function mutExcludes(newVals) {
      this.set('excludes', newVals);
      this._updateVal();
    },

    mutBoosts: function mutBoosts(newVals) {
      this.set('boosts', newVals);
      this._updateVal();
    },

    mutIncludes: function mutIncludes(newVals) {
      this.set('includes', newVals);
      this._updateVal();
    }

  });
});