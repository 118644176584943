define("sheer/components/preview-dependency-editor/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 8,
              "column": 4
            }
          },
          "moduleName": "sheer/components/preview-dependency-editor/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "list-group-item");
          var el2 = dom.createTextNode("\n          Name: ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n           \n          Asset Id: ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["inline", "one-way-input", [], ["value", ["subexpr", "@mut", [["get", "d.name", ["loc", [null, [5, 38], [5, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["get", "updateDepName", ["loc", [null, [5, 60], [5, 73]]], 0, 0, 0, 0], ["get", "d", ["loc", [null, [5, 74], [5, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 52], [5, 76]]], 0, 0]], ["loc", [null, [5, 16], [5, 78]]], 0, 0], ["inline", "one-way-input", [], ["value", ["subexpr", "@mut", [["get", "d.asset-id", ["loc", [null, [7, 42], [7, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["get", "updateDepRefId", ["loc", [null, [7, 68], [7, 82]]], 0, 0, 0, 0], ["get", "d", ["loc", [null, [7, 83], [7, 84]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 60], [7, 85]]], 0, 0]], ["loc", [null, [7, 20], [7, 87]]], 0, 0]],
        locals: ["d"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 0
          }
        },
        "moduleName": "sheer/components/preview-dependency-editor/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-md-8");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2, "class", "list-group");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        dom.setAttribute(el3, "class", "list-group-item");
        var el4 = dom.createTextNode("\n        Name: ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n           \n        Asset Id: ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "class", "fa fa-plus btn");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0, 1]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element2, [5]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element1, 1, 1);
        morphs[1] = dom.createMorphAt(element2, 1, 1);
        morphs[2] = dom.createMorphAt(element2, 3, 3);
        morphs[3] = dom.createElementMorph(element3);
        morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["block", "each", [["get", "deps", ["loc", [null, [3, 12], [3, 16]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 4], [8, 13]]]], ["inline", "one-way-input", [], ["value", ["subexpr", "@mut", [["get", "newDep.name", ["loc", [null, [10, 36], [10, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["get", "updateDepName", ["loc", [null, [10, 63], [10, 76]]], 0, 0, 0, 0], ["get", "newDep", ["loc", [null, [10, 77], [10, 83]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 55], [10, 84]]], 0, 0]], ["loc", [null, [10, 14], [10, 86]]], 0, 0], ["inline", "one-way-input", [], ["value", ["subexpr", "@mut", [["get", "newDep.asset-id", ["loc", [null, [12, 40], [12, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["get", "updateDepRefId", ["loc", [null, [12, 71], [12, 85]]], 0, 0, 0, 0], ["get", "newDep", ["loc", [null, [12, 86], [12, 92]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 63], [12, 93]]], 0, 0]], ["loc", [null, [12, 18], [12, 95]]], 0, 0], ["element", "action", [["get", "addDep", ["loc", [null, [13, 45], [13, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 36], [13, 53]]], 0, 0], ["content", "yield", ["loc", [null, [18, 0], [18, 9]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});