define('sheer/components/template-property-editors/file-uploader/component', ['exports', 'ember', 'ember-uploader', 'sheer/config/environment'], function (exports, _ember, _emberUploader, _sheerConfigEnvironment) {
  var assign = _ember['default'].assign;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = _emberUploader['default'].FileField.extend({
    classNames: ['control-label col-md-7'],

    willInsertElement: function willInsertElement() {
      this.sendAction('fileElementWillInsert', this.get('elementId'));
    },

    blobNameFromPropName: function blobNameFromPropName(propName, file) {
      return propName;
    },
    uploadFile: function uploadFile(file, uploader) {
      var fileParts = file.name.split('.');
      var extension = fileParts[fileParts.length - 1];

      var data = {
        'name': this.get('blobNameFromPropName')(this.get('propertyName'), file),
        extension: extension
      };
      var tmplId = this.get('templateId');
      if (tmplId) {
        assign(data, { 'templateId': tmplId });
      }

      uploader.upload(file, data);
      return extension;
    },

    filesDidChange: function filesDidChange(files) {
      this.sendAction('uploadStarted', files);

      var uploader = _emberUploader['default'].Uploader.create({
        url: _sheerConfigEnvironment['default'].host + '/asset_template/blob'
      });

      uploader.on('didUpload', this.get('onUpload'));
      uploader.on('didError', this.get('onError'));

      if (!isEmpty(files)) {
        var res = null;
        if (!this.get('multiple')) {
          res = this.uploadFile(files[0], uploader);
        } else {
          res = [];
          for (var i = 0; i < files.length; ++i) {
            // FileList data structure makes all the ES6 niceness go away :(
            res.push(this.uploadFile(files[i], uploader));
          }
        }
        return res;
      }
    }
  });
});