define('sheer/helpers/repl-camel-spaces', ['exports', 'ember'], function (exports, _ember) {
  exports.replCamelSpaces = replCamelSpaces;
  var assert = _ember['default'].assert;
  var eString = _ember['default'].String;
  var Helper = _ember['default'].Helper;

  function replCamelSpaces(params /*, hash*/) {
    assert('Helper takes exactly 1 parameter.', params.length === 1);
    return params[0] && eString.capitalize(eString.decamelize(params[0]).replace('_', ' '));
  }

  exports['default'] = Helper.helper(replCamelSpaces);
});