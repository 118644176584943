define('sheer/services/preview-render', ['exports', 'ember', 'sheer/config/environment', 'ember-concurrency'], function (exports, _ember, _sheerConfigEnvironment, _emberConcurrency) {
  var Service = _ember['default'].Service;
  var $ = _ember['default'].$;
  var get = _ember['default'].get;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Service.extend({
    _generatePreviewImage: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(prevAssetId, typ, height, quality, resFn) {
      var url, timeoutLen;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            url = _sheerConfigEnvironment['default'].renderHost + '/asset-renders/' + prevAssetId + '/renders/' + typ + '?height=' + height + '&quality=' + quality;
            timeoutLen = 1000;

          case 2:
            if (!url) {
              context$1$0.next = 11;
              break;
            }

            context$1$0.next = 5;
            return new RSVP.Promise(function (res, rej) {
              $.ajax({
                'type': timeoutLen === 1000 ? 'post' : 'get', //only post the first one
                url: url,
                'statusCode': {
                  278: function _(resp) {
                    if (resp.startsWith('/')) {
                      res('' + _sheerConfigEnvironment['default'].renderHost + resp);
                    } else {
                      resFn(resp);
                      res(null);
                    }
                  },
                  202: function _(resp) {
                    res('' + _sheerConfigEnvironment['default'].renderHost + resp);
                  },
                  200: function _(resp) {
                    if (resp && resp.status && resp.status === 'PENDING') {
                      res(url);
                    } else if (resp && resp.status && resp.status == 'FAILED') {
                      resFn('https://s3-us-west-2.amazonaws.com/lovepop-images-prod/design_previews/Error.png');
                      res(null);
                    } else {
                      res(null);
                    }
                  }
                }
              });
            });

          case 5:
            url = context$1$0.sent;
            context$1$0.next = 8;
            return (0, _emberConcurrency.timeout)(timeoutLen);

          case 8:
            timeoutLen += 1000;
            context$1$0.next = 2;
            break;

          case 11:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),
    startRenders: function startRenders(prevAssetId, thumbFn, prevFn) {
      this.get('_generatePreviewImage').perform(prevAssetId, 'jpg', 160, "lowest", thumbFn);
      this.get('_generatePreviewImage').perform(prevAssetId, 'jpg', 900, "lowest", prevFn);
    }

  });
});