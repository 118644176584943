define('sheer/routes/asset-library/types/index', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Route.extend({
    setupController: function setupController(controller, models) {
      this._super(controller, models);

      controller.set('types', models.types);
    },
    model: function model() {
      return RSVP.hash({
        types: this.get('store').findAll('asset-type')
      });
    }
  });
});