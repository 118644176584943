define('sheer/routes/preview-asset', ['exports', 'ember', 'sheer/config/environment'], function (exports, _ember, _sheerConfigEnvironment) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  var $ = _ember['default'].$;
  exports['default'] = Route.extend({
    model: function model(params) {
      return RSVP.hash({
        asset: this.get('store').findRecord('asset', params.assetId),
        doc: $.getJSON(_sheerConfigEnvironment['default'].host + '/preview/asset/' + params.assetId),
        assignments: []
      });
    }
  });
});