define('sheer/models/variable-def', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend({
    name: attr('string'),
    type: attr('string'),
    applicator: attr('string'),
    applicatorData: attr('string'),
    hint: attr('string'),
    'default': attr('string')
  });
});