define("sheer/components/template-property-editors/color-picker-text/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "sheer/components/template-property-editors/color-picker-text/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "one-way-input", [], ["value", ["subexpr", "@mut", [["get", "color", ["loc", [null, [1, 22], [1, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["get", "colorChanged", ["loc", [null, [1, 43], [1, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 35], [1, 56]]], 0, 0]], ["loc", [null, [1, 0], [1, 58]]], 0, 0], ["inline", "spectrum-color-picker", [], ["showInput", true, "flatMode", false, "preferredFormat", "rgb", "moveFiresChange", true, "color", ["subexpr", "@mut", [["get", "color", ["loc", [null, [2, 103], [2, 108]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", [["get", "colorChanged", ["loc", [null, [3, 41], [3, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 33], [3, 54]]], 0, 0]], ["loc", [null, [2, 0], [3, 56]]], 0, 0], ["content", "yield", ["loc", [null, [5, 0], [5, 9]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});