define('sheer/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  var Route = _ember['default'].Route;
  var service = _ember['default'].inject.service;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    session: service('session'),
    actions: {
      login: function login(username, password) {
        var _this = this;

        this.get('session').authenticate('authenticator:oauth2', username, password)['catch'](function (reason) {
          _this.set('loginErrorMessage', reason.error || reason);
        });
      }
    }
  });
});