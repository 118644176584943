define('sheer/components/type-editor/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    editorMode: 'code',
    documentSchemaObj: computed('model.documentSchema', function () {
      var docs = this.get('model.documentSchema');
      return docs && JSON.parse(docs);
    }),
    assetThumbnailUrl: computed('model.metadata.asset-thumbnail-url', function () {
      return this.get('model.metadata.asset-thumbnail-url') || '';
    }),
    previewAssetTemplateId: computed('model.metadata.preview-asset-template-id', function () {
      return this.get('model.metadata.preview-asset-template-id') || '';
    }),
    previewAssetRef: computed('model.metadata.preview-asset-ref', function () {
      return this.get('model.metadata.preview-asset-ref') || '';
    }),
    previewAssetMapping: computed('model.metadata.preview-asset-mapping', function () {
      return this.get('model.metadata.preview-asset-mapping') || '';
    }),
    previewAssetDeps: computed('model.id', 'model.metadata.preview-asset-deps', 'model.metadata.preview-asset-deps.[]', function () {
      return this.getWithDefault('model.metadata.preview-asset-deps', []);
    }),

    mutMetadata: function mutMetadata(field, val) {
      var metadata = this.get('model.metadata');
      if (!metadata) {
        metadata = {};
      }
      this.set('model.metadata', metadata);
      this.set('model.metadata.' + field, val);
    },

    version: computed('model.version', 'schemaChanged', function () {
      var modelVer = this.get('model.version');
      var schemaChanged = this.get('schemaChanged');

      return (modelVer || 0) + (schemaChanged ? 1 : 0);
    }),

    _getMetadataVal: function _getMetadataVal(name) {
      return this.get(name);
    },

    actions: {
      deleteType: function deleteType() {
        var id = this.get('id') || this.get('model.id');
        this.sendAction('delete', id);
      },
      changed: function changed(arg) {
        var oldSchema = this.get('documentSchema') || this.get('model.documentSchema');
        this.set('documentSchemaObj', arg);
        var newSchema = JSON.stringify(arg);
        this.set('documentSchema', newSchema);

        if (oldSchema && oldSchema !== newSchema) {
          this.set('schemaChanged', true);
        }
      },
      create: function create() {
        var schema = this.get('documentSchema') || this.get('model.documentSchema') || {};

        var id = this.get('isEdit') ? this.get('id') || this.get('model.id') : null;
        var name = this.get('name') || this.get('model.name');
        var metadata = {
          'asset-thumbnail-url': this._getMetadataVal('assetThumbnailUrl'),
          'preview-asset-template-id': this._getMetadataVal('previewAssetTemplateId'),
          'preview-asset-ref': this._getMetadataVal('previewAssetRef'),
          'preview-asset-mapping': this._getMetadataVal('previewAssetMapping'),
          'preview-asset-deps': this._getMetadataVal('previewAssetDeps')
        };
        var version = this.get('version') || this.get('model.version') || 0;

        this.sendAction('create', {
          id: id,
          name: name,
          version: version,
          metadata: metadata,
          documentSchema: schema
        }, this.get('isEdit'));
      }
    }
  });
});